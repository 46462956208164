<div class="asset-finance-page container-fluid">
  <div class="mat-h2">New Asset Finance Application</div>
  <application-stepper2 [linear]="false" #applicationStepper="applicationStepper2">
    <cdk-step [stepControl]="formGroupStep1" ><ng-content *ngTemplateOutlet="step1" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep1_b" ><ng-content *ngTemplateOutlet="step1_b" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep2" ><ng-content *ngTemplateOutlet="step2" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep3" ><ng-content *ngTemplateOutlet="step3" ></ng-content></cdk-step>
    @if (step3OnwardsOrganisationType === 'trust') {
      <cdk-step [stepControl]="formGroupStep4"><ng-content *ngTemplateOutlet="step4_1" ></ng-content></cdk-step>
    }
    @if (step3OnwardsOrganisationType === 'sole-trader') {
      <cdk-step [stepControl]="formGroupStep4"><ng-content *ngTemplateOutlet="step4_2" ></ng-content></cdk-step>
    }
    @if (step3OnwardsOrganisationType === 'partnership') {
      <cdk-step [stepControl]="formGroupStep4"><ng-content *ngTemplateOutlet="step4_3" ></ng-content></cdk-step>
    }
    @if (step3OnwardsOrganisationType === 'company') {
      <cdk-step [stepControl]="formGroupStep4"><ng-content *ngTemplateOutlet="step4_4" ></ng-content></cdk-step>
    }
    @if (step3OnwardsOrganisationType === 'other') {
      <cdk-step [stepControl]="formGroupStep4"><ng-content *ngTemplateOutlet="step4_5" ></ng-content></cdk-step>
    }
    <cdk-step [stepControl]="formGroupStep5" ><ng-content *ngTemplateOutlet="step5" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep6" ><ng-content *ngTemplateOutlet="step6" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep7" ><ng-content *ngTemplateOutlet="step7" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep8" ><ng-content *ngTemplateOutlet="step8" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep9" ><ng-content *ngTemplateOutlet="step9" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep10" ><ng-content *ngTemplateOutlet="step10" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep11" ><ng-content *ngTemplateOutlet="step11" ></ng-content></cdk-step>
  </application-stepper2>
</div>


<!--
##########################################
#  STEP 1:
##########################################
-->
<ng-template #step1>
  <div class="step1">
    <form [formGroup]="formGroupStep1" >
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <aggregator-search
            [mandatory]="true"
            [searchFn]="step1BrokerSearchFn"
            [title]="'Introducer'"
            [formControl]="formControlStep1Broker"
            [disabled]="isExternalUser"
          [mark]="applicationStepper.markTriggerSubject(formGroupStep1)"></aggregator-search>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <salesforce-contact-same-account-selection
            title="Introducer Contact"
            [getFunc]="step1GetSalesforceContactSameAccountFn"
            [salesforceAccountId]="this.step1OrgSalesforceId"
            [formControl]="formControlStep1BrokerContact"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep1)"
            [isMandatory]="true">
          </salesforce-contact-same-account-selection>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="mat-subtitle-1">Applicant's business name</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <business-search [placeholder]="businessSearchPlaceholder" [mark]="applicationStepper.markTriggerSubject(formGroupStep1)" [formControl]="formControlStep1Business" [searchFn]="step1SearchFn"></business-search>
        </div>
      </div>
      @if (formControlStep1Business.value && (formControlStep1Business.value.type === 'search-result') && ($any(formControlStep1Business.value).result.ABNAgeMonths ?? 0) < 12) {
        <div class="row mb-4">
          <div class="col-lg-6 col-md-6">
            <div class="mb-2">The ABN age for the business is less than 12 months old. Does the business owner(s) have a previous ABN with over 12 months of history?</div>
            <business-number-search
              [mark]="applicationStepper.markTriggerSubject(formGroupStep1)"
              [searchFn]="step4BusinessNumberSearchFn"
              [formControl]="formControlStep1PreviousBusiness"
            ></business-number-search>
          </div>
        </div>
      }
      @if (!step1NoOtherUsersInCompany) {
        <div class="brokerCorrespondent">
          <div class="row mb-4">
            <div class="col-lg-6 col-md-12 showCorrespondent">
              <mat-checkbox color="primary" [formControl]="formControlStep1ShowCorrespondent">Add additional broker correspondent</mat-checkbox>
              <span
                matTooltip="Add in additional broker correspondent"
                matTooltipPosition="after"
              class="tooltip-icon mdi mdi-help-circle-outline ml-1"></span>
            </div>
          </div>
          @if (formControlStep1ShowCorrespondent.value) {
            <div class="row">
              <div class="col-lg-6 col-md-12">
                <user-same-company-selection
                  title="Additional Broker Correspondent"
                  [getFunc]="step1GetUserSameCompanyFn"
                  [salesforceId]="this.step1OrgSalesforceId"
                  [formControl]="formControlStep1Correspondent"
                  [isMandatory]="false">
                </user-same-company-selection>
              </div>
            </div>
          }
        </div>
      }
    </form>
  </div>
</ng-template>

<!--
##########################################
#  STEP 1_b:
##########################################
-->
<ng-template #step1_b>
  <div class="step1_b">
    <form [formGroup]="formGroupStep1_b">
      <div class="row separator-bottom">
        <div class="col-12">
          <div class="mat-h3">Know your customer</div>
          <div class="mat-body">Have you met the applicant for {{organisationName}}?</div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <customer-applicant
            [formControl]="formControlStep1_bCustomerApplicantType"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep1_b)">
          </customer-applicant>
        </div>
      </div>
      <div class="row separator-bottom">
        <div class="col-12">
          <mat-checkbox color="primary" [formControl]="formControlStep1_bPrivacyStatementCheck">
            Do you have a signed privacy statement from the applicant?
          </mat-checkbox>
          @for (errorKey of errorKeys(formControlStep1_bPrivacyStatementCheck); track errorKey) {
            <mat-error>
              @if (formControlStep1_bPrivacyStatementCheck.touched && formControlStep1_bPrivacyStatementCheck.hasError(errorKey)) {
                {{errorMessages(formControlStep1_bPrivacyStatementCheck, errorKey)}}
              }
            </mat-error>
          }
        </div>
      </div>
    </form>
  </div>
</ng-template>

<!--
##########################################
#  STEP 2: details
##########################################
-->
<ng-template #step2>
  <div class="step2">

    <div class="row">
      <div class="col-12">
        <div class="mat-h3">
          How much would you like to borrow?
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 col-md-12">
        <currency-input [title]="formControlStep2FinanceType.value?.type === 'rental' ? 'Invoice amount (ex GST)' : 'Invoice amount (incl GST)'"
        [min]="MIN_LOAN_AMOUNT" [formControl]="formControlStep2InvoiceAmount" [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"></currency-input>
      </div>
    </div>

    <div class="row separator-bottom">
      <div class="col-12">
        <div class="mat-h3">Asset details</div>
        <div class="mat-body">Please provide details on the asset you would like to finance.</div>
      </div>
    </div>
    <div class="row separator-bottom">
      <div class="col-12">
        <asset-selection
          [categoryIndexFilter]="step2AssetCategoryIndexFilter"
          [searchFn]="step2AssetSearchFn"
          [formControl]="formControlStep2Asset"
          [mark]="applicationStepper.markTriggerSubject(formGroupStep2)">
        </asset-selection>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-3 col-md-12">
        <asset-condition
          [formControl]="formControlStep2AssetCondition"
          [mark]="applicationStepper.markTriggerSubject(formGroupStep2)">
        </asset-condition>
      </div>
      <div class="col-lg-3 col-md-12">
        <transaction-type-selection
          [formControl]="formControlStep2TransactionType"
          [mark]="applicationStepper.markTriggerSubject(formGroupStep2)">
        </transaction-type-selection>
      </div>
    </div>
    @if (_showAssetPurpose) {
      <div class="row separator-bottom">
        <div class="col-12">
          <div class="mat-h3">What is the reason for acquiring the asset?</div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <asset-purpose-selection
            [formControl]="formControlStep2AssetPurpose"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep2)">
          </asset-purpose-selection>
        </div>
      </div>
    }

    <div class="row separator-bottom">
      <div class="col-12">
        <div class="mat-h3">Finance details</div>
        <div class="mat-body separator-bottom">Let us know your preferred repayment terms</div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 col-md-12">
        <loan-terms-selection-with-input
            title="Loan term (in months)"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"
            [min]="1"
            [max]="84"
            [formControl]="formControlStep2LoanTerms"
          />
      </div>
      <div class="col-lg-3 col-md-12">
        <payment-frequency-selection
          [formControl]="formControlStep2PaymentFrequency"
          [mark]="applicationStepper.markTriggerSubject(formGroupStep2)">
        </payment-frequency-selection>
      </div>
    </div>


    <div class="row mt-3">
      <div class="col-lg-3 col-md-12">
        <finance-type-selection
          [formControl]="formControlStep2FinanceType"
          [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"
          [optionsFilter]="ASSET_FINANCE_OPTIONS_FILTER">
        </finance-type-selection>
      </div>
      <div class="col-lg-3 col-md-12">
        <yes-no
          title="Doc Fee financed"
          [formControl]="formControlStep2DocFeeFinanced"
          [mark]="applicationStepper.markTriggerSubject(formGroupStep2)">
        </yes-no>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6 col-md-12 balloonWidth">
        <balloon-payment #balloonPaymentComponent="balloonPaymentComponent"
          [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"
          [formControl]="formControlStep2BalloonPayment">
        </balloon-payment>
      </div>
    </div>


    <div class="row separator-bottom">
      <div class="col-12">
        <div class="mat-h3">Brokerage details</div>
        <div class="mat-body separator-bottom">Enter your origination fee (dollar amount up to $900 ex GST) and brokerage of amount financed (up to 8%)</div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 col-md-12">
        <currency-input title="Broker origination fee (ex GST)"
          [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"
          [min]="step2MinBrokerOriginationFee"
          [max]="step2MaxBrokerOriginationFee"
          [formControl]="formControlStep2BrokerOriginationFee">
        </currency-input>
      </div>
      <div class="col-lg-3 col-md-12">
        <brokerage-selection
          [max]="8"
          [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"
          [formControl]="formControlStep2Brokerage">
        </brokerage-selection>
      </div>
    </div>


    <div class="row separator-bottom">
      <div class="col-12">
        <div class="mat-h3">Risk profile</div>
        <div class="mat-body separator-bottom">Please provide us with further details so we can provide you with a more accurate quote</div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 col-md-12">
        <yes-no title="Property owner"
          [formControl]="formControlStep2PropertyOwner"
          [mark]="applicationStepper.markTriggerSubject(formGroupStep2)">
        </yes-no>
      </div>
      <div class="col-lg-3 col-md-12">
        <yes-no title="Adverse on file"
          [formControl]="formControlStep2AdverseOnFile"
          [mark]="applicationStepper.markTriggerSubject(formGroupStep2)">
        </yes-no>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-3 col-md-12">
        <yes-no title="Director Equifax score > 500"
          [formControl]="formControlStep2EquifaxScoreAboveThreshold"
          [mark]="applicationStepper.markTriggerSubject(formGroupStep2)">
        </yes-no>
      </div>
      @if (!formControlStep2PropertyOwner.value) {
        <div class="col-lg-3 col-md-12">
          <yes-no title="Has the business taken another commercial loan in the past 3 years?"
            [formControl]="formControlStep2PreviousLoan"
            [notselected]="true"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep2)">
          </yes-no>
        </div>
      }
    </div>



    <div class="row separator-bottom">
      <div class="col-12">
        <div class="mat-h3">Asset finance amount</div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 col-md-12">
        <currency-input
          [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"
          [min]="step2DepositMin"
          [maxExcluding]="step2DepositMax"
          title="Deposit amount (incl GST)"
          [formControl]="formControlStep2DepositAmount">
        </currency-input>
      </div>
      <div class="col-lg-3 col-md-12">
        <currency-input
          [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"
          title="Loan amount"
          [required]="false"
          [readonly]="true"
          [min]="step2MinLimit" [max]="step2MaxLimit"
          [formControl]="formControlStep2LoanAmount">
        </currency-input>
      </div>
    </div>

  </div>
</ng-template>

<!--
##########################################
#  STEP 3:
##########################################
-->
<ng-template #step3>
  <div class="step3">
    <form [formGroup]="formGroupStep3">
      <div class="row separator-bottom">
        <div class="col-12">
          <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
          </application-header-segment>
        </div>
      </div>

      <div class="row separator-bottom">
        <div class="col-12">
          <div class="mat-h3">Company details</div>
          <div class="mat-body separator-bottom">
            Please provide us with further details so we can provide you with a more accurate quote
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-12">
          <entity-type
            title="Organisation type"
            [formControl]="formControlStep3OrganisationType"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep3)">
          </entity-type>
        </div>
        <div class="col-lg-3 col-md-12">
          <abn [formControl]="formControlStep3Abn" [mark]="applicationStepper.markTriggerSubject(formGroupStep3)">
          </abn>
        </div>
        @if (step3OnwardsOrganisationType === 'company') {
          <div class="col-lg-3 col-md-12">
            <acn [formControl]="formControlStep3Acn"
              [isMandatory]="true"
              [mark]="applicationStepper.markTriggerSubject(formGroupStep3)">
            </acn>
          </div>
        }
        @if (step3OnwardsOrganisationType === 'trust') {
          <div class="col-lg-3 col-md-12">
            <acn [formControl]="formControlStep3Acn"
              [isMandatory]="false"
              [mark]="applicationStepper.markTriggerSubject(formGroupStep3)">
            </acn>
          </div>
        }
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-12 mt-2">
          <annual-revenue title="Annual revenue"
            [formControl]="formControlStep3AnnualRevenue"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep3)">
          </annual-revenue>
        </div>
        <div class="col-lg-3 col-md-12 mt-2">
          <yes-no
            title="Operates in a commercial premise?"
            [formControl]="formControlStep3OperatesInCommercialPremise"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep3)">
          </yes-no>
        </div>
      </div>


      <div class="row">
        <div class="col-lg-6 col-md-12">
          <primary-industry-selection
            [formControl]="formControlStep3PrimaryIndustry"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep3)">
          </primary-industry-selection>
        </div>
        <div class="col-lg-6 col-md-12">
          <secondary-industry-selection title="Industry sector"
            [formControl]="formControlStep3IndustrySector"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep3)">
          </secondary-industry-selection>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-12">
          <custom-address
            title="Address"
            [formControl]="formControlStep3Address"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep3)">
          </custom-address>
        </div>
        <div class="col-lg-3 col-md-12">
          <mobile [formControl]="formControlStep3BusinessLandline"
            [mandatory]="false"
            [onlyMobile]="false"
          title="Business Landline"></mobile>
        </div>
      </div>

    </form>
  </div>
</ng-template>

<!--
##########################################
#  STEP 4:
##########################################
-->
<ng-template #step4_1> <!-- trust -->
  <div class="step4">
    <form [formGroup]="formGroupStep4">
      <div class="row separator-bottom">
        <div class="col-12">
          <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
          </application-header-segment>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <trustee
            [showAssetsAndLiabilities]="false"
            [formControl]="formControlStep4Applicant"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep4)"
            [showAddressForm]="showAddressFormFlag"
            [businessNumberSearchFn]="step4BusinessNumberSearchFn">
          </trustee>
          <!--
          trustee / formControlStep4Applicant: {{formControlStep4Applicant.value | json}}
          trustee / formControlStep4Applicat Valid: {{formGroupStep4.valid | json}}
          -->
        </div>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #step4_2> <!-- sole trader -->
  <div class="step4">
    <form [formGroup]="formGroupStep4">
      <div class="row separator-bottom">
        <div class="col-12">
          <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
          </application-header-segment>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <sole-trader
            [formControl]="formControlStep4Applicant"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep4)"
            [showAddressForm]="showAddressFormFlag">
          </sole-trader>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #step4_3> <!-- partnership -->
  <div class="step4">
    <form [formGroup]="formGroupStep4">
      <div class="row separator-bottom">
        <div class="col-12">
          <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
          </application-header-segment>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <partner
            [showAssetsAndLiabilities]="false"
            [formControl]="formControlStep4Applicant"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep4)"
            [businessNumberSearchFn]="step4BusinessNumberSearchFn"
            [showAddressForm]="showAddressFormFlag">
          </partner>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #step4_4> <!-- company -->
  <div class="step4">
    <form [formGroup]="formGroupStep4">
      <div class="row separator-bottom">
        <div class="col-12">
          <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
          </application-header-segment>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <director
            [showAssetsAndLiabilities]="false"
            [formControl]="formControlStep4Applicant"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep4)"
            [showAddressForm]="showAddressFormFlag">
          </director>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #step4_5> <!-- other (member) -->
  <div class="step4">
    <form [formGroup]="formGroupStep4">
      <div class="row separator-bottom">
        <div class="col-12">
          <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
          </application-header-segment>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <member
            [showAssetsAndLiabilities]="false"
            [formControl]="formControlStep4Applicant"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep4)"
            [businessNumberSearchFn]="step4BusinessNumberSearchFn"
            [showAddressForm]="showAddressFormFlag">
          </member>
        </div>
      </div>
    </form>
  </div>
</ng-template>


<!--
##########################################
#  STEP 5:
##########################################
-->
<ng-template #step5>
  <div class="step5">
    <div class="row separator-bottom">
      <div class="col-12">
        <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
        </application-header-segment>
      </div>
    </div>
    <form [formGroup]="formGroupStep5">
      <div class="row">
        <div class="col-12">
          <guarantor [businessNumberSearchFn]="step5BusinessNumberSearchFn"
            [formControl]="formControlStep5Guarantors"
            (events)="step5GuarantorEvent($event)"
            [showAssetsAndLiabilities]="false"
            [mandatoryGuarantors]="step5MandatoryGuarantors"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep5)"
            [showAddressForm]="showAddressFormFlag">
          </guarantor>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<!--
##########################################
#  STEP 6:
##########################################
-->
<ng-template #step6>
  <div class="step6">
    <div class="row separator-bottom">
      <div class="col-12">
        <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
        </application-header-segment>
      </div>
    </div>
    <form [formGroup]="formGroupStep6">
      <div class="row">
        <div class="col-12">
          <select-contact
            [predefinedContactSelection]="step6PossiblePrimaryContacts"
            [formControl]="formControlStep6PrimaryContact"
          [mark]="applicationStepper.markTriggerSubject(formGroupStep6)"></select-contact>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<!--
##########################################
#  STEP 7:
##########################################
-->
<ng-template #step7>
  <div class="step7">
    <form [formGroup]="formGroupStep7">
      <div class="row separator-bottom">
        <div class="col-12">
          <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
          </application-header-segment>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <mat-form-field>
            <mat-label>Bankstatement Brokerflow document ID <span class="mdi mdi-information-outline pointer-event-mat-tooltip" matTooltip="If you have already retrieved customer's bank statements using brokerflow, you can provide the ID here and we will not ask the customer to re-provide their bank statements when we assess the application"></span></mat-label>
            <input type="text" matInput [formControl]="formControlStep7BrokerflowDocumentId">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <reference [formControl]="formControlStep7References"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep7)">
          </reference>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<!--
##########################################
#  STEP 8:
##########################################
-->
<ng-template #step8>
  <div class="step8">

    <form [formGroup]="formGroupStep8">
      <div class="row separator-bottom">
        <div class="col-12">
          <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
          </application-header-segment>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="mat-h3">Upload Driver Licence</div>
        </div>
      </div>
      <div class="row separator-bottom">
        <div class="col-lg-6 col-md-12">
          <message-box type="info" title="Before uploading">
            <ul>
              <li>Ensure both the front and back of the licence are supplied</li>
              <li>Ensure the text is clear and readable</li>
              <li>If using PDF, ensure the front of the driver licence is on the first page</li>
              <li>We don’t accept pictures of digital driver licences</li>
            </ul>
          </message-box>
        </div>
      </div>

      <div class="row separator-bottom">
        <div class="col-lg-6 col-md-12">
          <uploaded-files-list   [uploadedFiles]="step8UploadedDocs" (events)="onStep8DeleteUploadedDoc($event)"></uploaded-files-list>
        </div>
      </div>

      @for (dr of step8DriverLicenses; track dr) {
        <div class="row separator-bottom">
          <div class="col-lg-6 col-md-12">
            <upload-file [maxFilesAllowed]="2" [mandatory]="!skipFileUploadValidation()" [mark]="applicationStepper.markTriggerSubject(formGroupStep8)" title="{{dr.name}}'s Driver Licence" [formControl]="dr.formControl" [allowMultiple]="true"></upload-file>
          </div>
        </div>
      }

    </form>
  </div>
</ng-template>

<!--
##########################################
#  STEP 9:
##########################################
-->
<ng-template #step9>
  <div class="step9">
    <form [formGroup]="formGroupStep9">
      <div class="row separator-bottom">
        <div class="col-12">
          <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
          </application-header-segment>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="mat-h3">Other supporting documents</div>
        </div>
      </div>

      <div class="row separator-bottom">
        <div class="col-lg-6 col-md-12">
          <uploaded-files-list   [uploadedFiles]="step9UploadedDocs" (events)="onStep9DeleteUploadedDoc($event)"></uploaded-files-list>
        </div>
      </div>

      <div class="row separator-bottom">
        <div class="col-lg-6 col-md-12">
          <upload-files-multi-tag-dialog
            #uploadFilesMultiTagDialogComponent="uploadFilesMultiTagDialogComponent"

            [docs]="formControlStep9OtherSupportingDocuments.value ?? []"
            [metadata]="step9OtherDocMetadata"
            [defaultTags]="applicationDefaultDocuments(true)"
            (uploadDocs)="onStep9UploadOtherDoc($event)"
            (deleteDoc)="onStep9DeleteSupportingDoc($event)"
          ></upload-files-multi-tag-dialog>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<!--
##########################################
#  STEP 10:
##########################################
-->
<ng-template #step10>
  <div class="step10">
    <form [formGroup]="formGroupStep10">
      <div class="row separator-bottom">
        <div class="col-12">
          <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
          </application-header-segment>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="mat-subtitle-1">Overview</div>
        </div>
      </div>

      <div class="row separator-bottom">
        <div class="col-lg-6 col-md-12">
          <mat-form-field>
            <mat-label>Application notes </mat-label>
            <textarea matInput rows="10" wrap="hard" maxlength="7000" [formControl]="formControlStep10ApplicationNotes"></textarea>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<!--
##########################################
#  STEP 11:
##########################################
-->
<ng-template #step11>
  <div class="step11">
    <div class="row separator-bottom">
      <div class="col-12">
        <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
        </application-header-segment>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <asset-finance-summary [summary]="step11Summary"></asset-finance-summary>
      </div>
    </div>

  </div>
</ng-template>
