import {HttpContext, HttpParams} from '@angular/common/http';
import {catchError, interval, Observable, of, OperatorFunction, Subject, takeUntil} from 'rxjs';
import {untilDestroyed} from '@ngneat/until-destroy';
import cryptojs from 'crypto-js';
import tinycolor2 from 'tinycolor2';
import {
  AccreditationSelectionType,
  applicantIndividual,
  BusinessNumberSearchResult,
  defaultDocuments,
  DocumentTag,
  DocumentWorklist,
  DocumentWorklistCategory,
  DocumentWorklists,
  isInternalUser,
  isInternalUserOrBroker,
  LoanTermType,
  PortalLoginUser,
  primaryCommercialEntity,
  PrivateSaleType,
  TransactionValue,
  UserandCustomerPayway,
  OnCompleteMessage,
  OnCompleteMessageSuccess,
  CustomerApplicantTypeValue,
  CustomerApplicantTypeOptions,
  OnCompleteMessageError,
  getAssetCategory,
  getAssetCategoryType,
  isJson,
  yesNoToBoolean,
  LtvSelectionType,
  SecurityTypeSelectionType,
  SalesforceContactSameAccountSelectionValue,
  TitleSelectionType,
  TitleSelectionValueOptions,
  WhitelabelEntityDetails,
  IdentificationComponentValue,
  MaterialShades,
  WhitelabelStyles,
  FacilityEstablishmentType,
  PortalTheme, TransactionValueOptions, AssetFinanceProductType,
  RepaymentTypeOptions,
} from '@portal-workspace/grow-shared-library';
import {ErrorStateMatcher} from '@angular/material/core';
import {
  AbstractControl,
  FormGroupDirective,
  NgForm,
  FormControl,
  FormGroup,
  FormArray
} from '@angular/forms';
import {
  Address2ComponentValue,
  AggregatorSearchComponentValue, ApplicationSelectionType,
  BrowserInfo,
  EntityType, FacilityEstablishmentValue,
  PaginationInfo,
  SortTypes,
  toUser, UserSelectionValue
} from '@portal-workspace/grow-shared-library';
import { DigitalIdComponentEvent } from '@portal-workspace/grow-shared-library';
import {DigitalIdAuthenticationPayload, ParsedDigitalIdResponse} from '@portal-workspace/grow-shared-library';
import {parseJSON, parseRawAddress, stringifyJSON} from '@portal-workspace/grow-shared-library';
import {ApplicationApplicant, Customer, NotNullable, User} from '@portal-workspace/grow-shared-library';
import {CdkStepper, StepperSelectionEvent} from '@angular/cdk/stepper';
import {createMask} from '@ngneat/input-mask';
import moment from 'moment';
import {v4} from 'uuid';
import sha256 from 'crypto-js/sha256';
import {
  EntityTrustee,
  IndividualTrustee, TrusteeValue
} from '@portal-workspace/grow-shared-library';
import {
  EntityMember,
  IndividualMember, MemberValue
} from '@portal-workspace/grow-shared-library';
import {
  EntityPartner,
  IndividualPartner, PartnerValue
} from '@portal-workspace/grow-shared-library';
import {SoleTraderValue} from '@portal-workspace/grow-shared-library';
import {
  DirectorValue, DirectorValueWithoutPropertyOwnerAddress,
  DirectorValueWithPropertyOwnerAddress
} from '@portal-workspace/grow-shared-library';
import {
  EntityGuarantor,
  GuarantorValue, IndividualGuarantor
} from '@portal-workspace/grow-shared-library';
import {GenderValue} from '@portal-workspace/grow-shared-library';
import {TimeInAddressValue} from '@portal-workspace/grow-shared-library';
import {EmployerValue} from '@portal-workspace/grow-shared-library';
import {
  EntityTypes,
  EntityTypeValue,
  EntityTypeValueOptions as EntityTypeOptions
} from '@portal-workspace/grow-shared-library';
import {BusinessSearchValue} from '@portal-workspace/grow-shared-library';
import {
  Address,
  Application,
  CommercialEntity, Individual, IndividualMortgageLiability, IndividualPropertyAsset,
  IndividualWithAssetsAndLiabilities, SaveApplicationData
} from '@portal-workspace/grow-shared-library';
import {
  PersonalAssetValueOptions as assetTypesOptions,
  PersonalAsset
} from '@portal-workspace/grow-shared-library';
import {
  PersonalLiabilitiesValueOption as liabilityTypesOptions,
  PersonalLiability
} from '@portal-workspace/grow-shared-library';
import {
  AssetSelectionComponentValue
} from '@portal-workspace/grow-shared-library';
import {AssetConditionValue} from '@portal-workspace/grow-shared-library';
import _ from 'lodash';
import {
  LoanTermValue,
  ConsumerLoanTermValueOptions as ConsumerLoanTermsOptions,
  LoanTermValueOptions as LoanTermsOptions
} from '@portal-workspace/grow-shared-library'
import {
  BusinessLoanTermValueOptions as BusinessLoanTermsOptions
} from '@portal-workspace/grow-shared-library';
import {
  CommercialLoanTermsValueOptions as CommercialLoanTermsOptions
} from '@portal-workspace/grow-shared-library';
import {
  PaymentFrequencyValueOptions as PaymentFrequencyOptions,
  PaymentFrequencyValue
} from '@portal-workspace/grow-shared-library';
import {
  FinanceTypeValue,
  FinanceTypeValueOptions as FinanceTypeOptions
} from '@portal-workspace/grow-shared-library';
import {
  BalloonPaymentValue,
  BalloonPaymentValueOptions as BalloonPaymentOptions
} from '@portal-workspace/grow-shared-library';
import {
  BrokerageSelectionValue,
  BrokerageSelectionValueOptions as BrokerageOptions
} from '@portal-workspace/grow-shared-library';
import {
  FacilityEstablishmentValueOptions as FacilityEstablishmentOptions
} from '@portal-workspace/grow-shared-library';
import {
  PrimaryIndustrySelectionValueOptions as PrimaryIndustrySelectionOptions,
  PrimaryIndustrySelectionValue
} from '@portal-workspace/grow-shared-library';
import {
  TitleSelectionValueOptions as TitleOptions,
  TitleSelectionValue
} from '@portal-workspace/grow-shared-library'
import {
  ContactValue,
  SelectContactValue
} from '@portal-workspace/grow-shared-library';
import {
  AssetCategoryTypeSelectionValue
} from '@portal-workspace/grow-shared-library';
import {Moment} from 'moment';
import {
  PropertyOwnerWithAddressValue
} from '@portal-workspace/grow-shared-library';

import {
  SecondaryIndustrySelectionValue
} from '@portal-workspace/grow-shared-library';
import {
  ReferenceValueOptions as referenceTypeOptions,
  ReferenceValue
} from '@portal-workspace/grow-shared-library';
import {
  LoanPurposeValue,
  LoanPurposeValueOptions as LoanPurposeOptions
} from '@portal-workspace/grow-shared-library';
import {
  MaritialStatusSelectionValue,
  MaritialStatusSelectionValueOptions as MaritialStatusOptions
} from '@portal-workspace/grow-shared-library'
import {
  EmploymentStatusSelectionValue,
  EmploymentStatusSelectionValueOptions as EmploymentStatusOptions
} from '@portal-workspace/grow-shared-library';
import {
  IncomePeriodOptions as incomePeriodOptions,
  IncomeSelectionValue
} from '@portal-workspace/grow-shared-library';
import {
  Policy,
  PolicyValue
} from '@portal-workspace/grow-shared-library';
import {
  CoverTypeSelectionValueOptions as CoverTypeOptions
} from '@portal-workspace/grow-shared-library';
import {
  AuthorisedSignatoryRoleOptions as authorisedSignatoryRoleOptions,
  AuthorisedSignatoryValue
} from '@portal-workspace/grow-shared-library';
import {fieldErrorMessages} from '../field-error-messages';
import {PortalHotToastService} from './portal-hot-toast-component/hot-toast.service';
import {ApplicationDialogService} from './application-dialog-component/application-dialog.service';
import {NgxMaskModule} from 'ngx-mask';
import {ChangeDetectorRef, inject, ViewRef} from '@angular/core';
import {createAsyncStore, createSyncState} from "@ngneat/loadoff";



declare var require: any;
// const parser = require('australia-address-parser');
import {Parser} from '@tmjeee/australia-address-parser';
import hexRgb from 'hex-rgb';
import {environment} from "../../../../../apps/portal2/src/environments/environment";

export const TOKEN_GROW_USER = 'growUser';
export const TOKEN_GROW_ACCESS_TOKEN = 'growAccessToken';
export const TOKEN_GROW_WHITELABEL = 'growWhiteLabel';
export const TOKEN_GROW_PORTAL_THEME = 'growPortalTheme';
export const TOKEN_GROW_REFRESH_TOKEN = 'growRefreshToken';
export const TOKEN_GROW_IS_2FA_ENTERED_WHEN_LOGIN = 'growIs2faEnteredWhenLogin';
export const TOKEN_LAST_VISITED_URL = 'growLastVisitedUrl';
export const TOKEN_LAST_VISITED_EMAIL = 'growLastVisitedEmail';

const parser = new Parser();
let encryptionKey = '';

export const setupComponentUtils = (options: {
  encryptionKey: string
}) => {
  encryptionKey = options.encryptionKey;
}

// ====================================================
// === localStroage encryption / decription
// ====================================================
const getFromLocalStorage = (key: string, opts: {
  returnNullIfNotJson: boolean
} = {
  returnNullIfNotJson: false,
}): string | null => {
  const val = localStorage.getItem(key);
  if (val != null) {
    try {
      const decryptedVal = cryptojs.AES.decrypt(val, encryptionKey).toString(cryptojs.enc.Utf8);
      return decryptedVal;
    } catch(err) {
      if (opts.returnNullIfNotJson) {
        clearAllStorage();
        if (isJson(val)) {
          console.log(`Error decrypting returning as JSON `, val);
          return val;
        } else {
          console.log(`Error decrypting return null`, val);
          return null;
        }
      }
    }
  }
  return val;
}

const setIntoLocalStorage = (key: string, value: string) => {
  const encryptedValue = cryptojs.AES.encrypt(value, encryptionKey).toString();
  localStorage.setItem(key, encryptedValue);
}



// ====================================================
// === handle ClientError from API
// ====================================================
// NOTE: handled in global-error-handler, use this to override at service level
// export const handleClientErrorFromApi = (cb: <T>()=>Observable<T>) => {
//   return catchError(err => {
//     // note: err will be HttpErrorResponse
//     // console.log('***** err', err);
//     if (err.error?.isClientError) {
//       // p.error(err.error.message);
//       // return of(null);
//       return cb();
//     }
//     throw err;
//   });
// }


// =====================================================
// === Download file and open in new window
// =====================================================
export const openWindowAndDownload = (blob: Blob) => {
  const url = window.URL.createObjectURL(blob);
  window.open(url, '_blank')?.focus();
  window.URL.revokeObjectURL(url);
}

export const openWindowAndDownloadWithFilename = (filename: string, blob: Blob) => {
  const url = window.URL.createObjectURL(blob);
  const downloadLink = window.document.createElement('a');
  downloadLink.href = url;
  downloadLink.download = filename;
  downloadLink.target = '_blank';
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
  window.URL.revokeObjectURL(url);
}

export const openWindowWithLink = (link: string) => {
  window.open(link, '_blank');
}



// =====================================================
// === Browser Detection
// =====================================================

export const setBrowseHeightAsCssVariable = () => {
  const windowHeight = window.innerHeight;
  const doc = document.documentElement;
  doc.style.setProperty('--portal-window-height', `${windowHeight}px`);
}

export const browserInfo = (): BrowserInfo => {
  let nVer = navigator.appVersion;
  let nAgt = navigator.userAgent;
  let browserName  = navigator.appName;
  let fullVersion  = ''+parseFloat(navigator.appVersion);
  let majorVersion = parseInt(navigator.appVersion,10);
  let nameOffset,verOffset,ix;

  // In Opera, the true version is after "Opera" or after "Version"
  if ((verOffset=nAgt.indexOf("Opera"))!=-1) {
    browserName = "Opera";
    fullVersion = nAgt.substring(verOffset+6);
    if ((verOffset=nAgt.indexOf("Version"))!=-1)
      fullVersion = nAgt.substring(verOffset+8);
  }
  // In MSIE, the true version is after "MSIE" in userAgent
  else if ((verOffset=nAgt.indexOf("MSIE"))!=-1) {
    browserName = "Microsoft Internet Explorer";
    fullVersion = nAgt.substring(verOffset+5);
  }
  // In Chrome, the true version is after "Chrome"
  else if ((verOffset=nAgt.indexOf("Chrome"))!=-1) {
    browserName = "Chrome";
    fullVersion = nAgt.substring(verOffset+7);
  }
  // In Safari, the true version is after "Safari" or after "Version"
  else if ((verOffset=nAgt.indexOf("Safari"))!=-1) {
    browserName = "Safari";
    fullVersion = nAgt.substring(verOffset+7);
    if ((verOffset=nAgt.indexOf("Version"))!=-1)
      fullVersion = nAgt.substring(verOffset+8);
  }
  // In Firefox, the true version is after "Firefox"
  else if ((verOffset=nAgt.indexOf("Firefox"))!=-1) {
    browserName = "Firefox";
    fullVersion = nAgt.substring(verOffset+8);
  }
  // In most other browsers, "name/version" is at the end of userAgent
  else if ( (nameOffset=nAgt.lastIndexOf(' ')+1) <
    (verOffset=nAgt.lastIndexOf('/')) )
  {
    browserName = nAgt.substring(nameOffset,verOffset);
    fullVersion = nAgt.substring(verOffset+1);
    if (browserName.toLowerCase()==browserName.toUpperCase()) {
      browserName = navigator.appName;
    }
  }
  // trim the fullVersion string at semicolon/space if present
  if ((ix=fullVersion.indexOf(";"))!=-1)
    fullVersion=fullVersion.substring(0,ix);
  if ((ix=fullVersion.indexOf(" "))!=-1)
    fullVersion=fullVersion.substring(0,ix);

  majorVersion = parseInt(''+fullVersion,10);
  if (isNaN(majorVersion)) {
    fullVersion  = ''+parseFloat(navigator.appVersion);
    majorVersion = parseInt(navigator.appVersion,10);
  }

  return {
    browserName,
    fullVersion,
    majorVersion: String(majorVersion),
    appName: navigator.appName,
    userAgent: navigator.userAgent,
  }
}


// ====================================================
// ======= FormControl Validations / Validators
// ====================================================
export const formControlErrorKeys = (formControl: AbstractControl, onlyFirstErrorKey = true): string[] => {
  const keys =  Object.keys(formControl?.errors ?? {});
  if (onlyFirstErrorKey) {
    return keys.length ? [keys[0]] : keys
  }
  return keys;
}
export const formControlErrorMessage = (formControl: AbstractControl, errorKey: string): string => {
  const r = formControl?.errors?.[errorKey];
  if (r) {
    const f = (fieldErrorMessages as any)?.[errorKey];
    if (!!f) {
      if (typeof f == 'string') {
        return f;
      } else if (typeof f == 'function') {
        return f(r);
      }
    }
  }
  return '';
}

export const validateEmail = (email: string): boolean => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}


// =============================
// === pagination
// =============================

export const toPaginationInfo = (opt: {
  page: {
    limit: number,
    offset: number, // pageIndex
  }
  filter?: string | null,
  sorts?: SortTypes,
}): PaginationInfo => {
  return {
    limit: opt.page.limit,
    offset: opt.page.offset,   // pageIndex
    filter: opt.filter ?? null,
    sorts: opt.sorts ?? null,
    start: opt.page.limit * opt.page.offset,
  } as const;
};

export const paginationUrl = (url: string, opt: PaginationInfo): string => {
  const _url = _.trim(url ?? '');
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.set('limit', `${opt.limit}`);
  urlSearchParams.set('offset', `${opt.offset}`);
  if (opt.sorts && opt.sorts.length) {
    const sorts = opt.sorts.reduce<string[]>((acc, sort) => {
      if (sort) {
        acc.push(_.lowerCase(sort.dir) == 'asc' ? sort.prop: `-${sort.prop}`);
      }
      return acc;
    }, []);
    urlSearchParams.set('sorts', sorts.join(','));
  }
  if (opt.filter) {
    urlSearchParams.set('filter', opt.filter);
  }
  return (_url.indexOf('?') > 0) ?
    `${_url}&${urlSearchParams.toString()}` :
    `${_url}?${urlSearchParams.toString()}`;
}


// ==========================
// ====  Setup untildestroy
// ==========================
export const setupUntilDestroy = (component: any) => {
  interval(1000)
    .pipe(untilDestroyed(component))
    .subscribe();
}
export const untilDestroyedFn = () => {
  const subject = new Subject<void>();
  const viewRef = inject(ChangeDetectorRef) as ViewRef;
  viewRef.onDestroy(() => {
    subject.next();
    subject.complete();
  });
  return takeUntil(subject.asObservable());
}


// ==========================
// ====  Angular Material UI Helper class
// ==========================
export class CustomErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: AbstractControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched));
    // return false;
  }
}

// TODO: temporary typing fix
export const getAddress2ComponentValueFormControlValueFn = (formControl: FormControl): ()=> { observable: Observable<Address2ComponentValue>, value: Address2ComponentValue } => {
  return () => {
    return { observable: formControl.valueChanges, value: formControl.value };
  }
}

// ==========================
// ====  DigitalID work
// ==========================
export const parseDigitalIdResponse = (e: DigitalIdComponentEvent): ParsedDigitalIdResponse => {
  const r: DigitalIdAuthenticationPayload = (e.authResult as any).payload;
  const address: string = r.individualInfo.address.formatted.replace('\n', ' ');
  const parsedAddress = parser.parseLocation(address);
  return {
    birthdate: r.individualInfo.birthdate,
    firstName: r.individualInfo.given_name,
    middleName: r.individualInfo.middle_name,
    lastName: r.individualInfo.family_name,
    country: r.individualInfo.address.country,
    state: r.individualInfo.address.region,
    postcode: r.individualInfo.address.postal_code,
    suburb: r.individualInfo.address.locality,
    rawAddress: r.individualInfo.address.formatted.replace('\n', ' '),
    streetName: parsedAddress.streetName ? parsedAddress.streetName : '',
    streetNumber: parsedAddress.streetNumber ? parsedAddress.streetNumber : '',
    streetType: parsedAddress.streetType ? parsedAddress.streetType : '',
    unitNumber: parsedAddress.unitNumber ? parsedAddress.unitNumber : '',
  };
};


// ==========================
// ====   Http Client utils
// ==========================
export type HttpOptions = {
  headers?: {
    [header: string]: string | string[];
  };
  context?: HttpContext;
  observe?: 'body';
  params?: HttpParams | {
    [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
  };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}

export const httpOptions = (options?: HttpOptions) => {
  const o =  options ? {...options} : {headers: {}};
  const accessToken = getAccessToken();
  if (accessToken) {
    if (!o.headers) {
      o.headers = {};
    }
    if (!o.headers['Authorization']) {
      o.headers['Authorization'] = `Bearer ${accessToken}`;
    }
  }
  return o;
}

export const httpOptionsForDirectSale = (token: string): HttpOptions => {
  return {
    headers: {
      Authorization: token,
    }
  };
}


// ==========================
// ====   Local / Session Storage
// ==========================
export const storeTokens = (tokens: {user?: PortalLoginUser, accessToken?: string, refreshToken?: string}) => {
  if (tokens.user) {
    //localStorage.setItem(TOKEN_GROW_USER, stringifyJSON(tokens.user));
    setIntoLocalStorage(TOKEN_GROW_USER, stringifyJSON(tokens.user));
  }
  if (tokens.refreshToken) {
    // localStorage.setItem(TOKEN_GROW_REFRESH_TOKEN, tokens.refreshToken);
    setIntoLocalStorage(TOKEN_GROW_REFRESH_TOKEN, tokens.refreshToken);
  }
  if (tokens.accessToken) {
    // localStorage.setItem(TOKEN_GROW_ACCESS_TOKEN, tokens.accessToken);
    setIntoLocalStorage(TOKEN_GROW_ACCESS_TOKEN, tokens.accessToken);
  }
}


export const storePortalTheme = (theme: PortalTheme | null) => {
  if (theme) {
    setIntoLocalStorage(TOKEN_GROW_PORTAL_THEME, theme);
  } else {
    localStorage.removeItem(TOKEN_GROW_PORTAL_THEME);
  }
}

export const getPortalThemeFromStorage = (): PortalTheme => {
  const portalTheme = getFromLocalStorage(TOKEN_GROW_PORTAL_THEME);
  if (portalTheme) {
    return portalTheme == 'dark' ? 'dark' : 'light';
  }
  return 'light';
}

export const storeWhitelabelIntoStorage = (w: WhitelabelEntityDetails | null) => {
  if (w) {
    setIntoLocalStorage(TOKEN_GROW_WHITELABEL, stringifyJSON(w));
  } else {
    localStorage.removeItem(TOKEN_GROW_WHITELABEL);
  }
}

export const getWhiteLabelFromStorage = (): WhitelabelEntityDetails | null => {
  return parseJSON(getFromLocalStorage(TOKEN_GROW_WHITELABEL));
}

export const getAccessToken = (): string | null => {
  // return parseJSON(localStorage.getItem(TOKEN_GROW_ACCESS_TOKEN));
  return parseJSON(getFromLocalStorage(TOKEN_GROW_ACCESS_TOKEN));
}

export const getRefreshToken = (): string | null => {
  // return parseJSON(localStorage.getItem(TOKEN_GROW_REFRESH_TOKEN));
  return parseJSON(getFromLocalStorage(TOKEN_GROW_REFRESH_TOKEN));
}

export const getUser = (): PortalLoginUser | null => {
  // return toUser(parseJSON(localStorage.getItem(TOKEN_GROW_USER)));
  return toUser(parseJSON(getFromLocalStorage(TOKEN_GROW_USER, {returnNullIfNotJson: true})));
}

export const store2faEnteredWhenLogin = (tfaEnteredWhenLogin: boolean) => {
  // todo: better encryption? - encryption and decription must match with is2faEnteredWhenLogin
  const msg = sha256(`${getUser()?.Email??''}:${tfaEnteredWhenLogin}`).toString();
  localStorage.setItem(TOKEN_GROW_IS_2FA_ENTERED_WHEN_LOGIN, msg);
}

export const is2faEnteredWhenLogin = (): boolean => {
  // todo: better encryption? - encryption and decription must match with store2faEnteredWhenLogin
  const msg = sha256(`${getUser()?.Email??''}:${true}`).toString();
  const msgStored = localStorage.getItem(TOKEN_GROW_IS_2FA_ENTERED_WHEN_LOGIN);
  return msgStored == msg;
}

export const storeLastVisit = (user?: PortalLoginUser | null, url?: string | null) => {
  if (url) {
    // localStorage.setItem(TOKEN_LAST_VISITED_URL, url);
    setIntoLocalStorage(TOKEN_LAST_VISITED_URL, url);
  }
  if (user && user.Email) {
    // localStorage.setItem(TOKEN_LAST_VISITED_EMAIL, user.Email);
    setIntoLocalStorage(TOKEN_LAST_VISITED_EMAIL, user.Email);
  } else {
    localStorage.removeItem(TOKEN_LAST_VISITED_EMAIL);
  }
}

export const getLastVisitedUrl = () => {
  //m return localStorage.getItem(TOKEN_LAST_VISITED_URL);
  return getFromLocalStorage(TOKEN_LAST_VISITED_URL);
}

export const getLastVisitedEmail = () => {
  // return localStorage.getItem(TOKEN_LAST_VISITED_EMAIL);
  return getFromLocalStorage(TOKEN_LAST_VISITED_EMAIL);
}


export const clearStorage = () => {
  localStorage.removeItem(TOKEN_GROW_ACCESS_TOKEN);
  localStorage.removeItem(TOKEN_GROW_REFRESH_TOKEN);
  localStorage.removeItem(TOKEN_GROW_USER);
  localStorage.removeItem(TOKEN_GROW_IS_2FA_ENTERED_WHEN_LOGIN);
  localStorage.removeItem(TOKEN_GROW_WHITELABEL);
}

export const clearAllStorage = () => {
  localStorage.removeItem(TOKEN_GROW_ACCESS_TOKEN);
  localStorage.removeItem(TOKEN_GROW_REFRESH_TOKEN);
  localStorage.removeItem(TOKEN_GROW_USER);
  localStorage.removeItem(TOKEN_GROW_IS_2FA_ENTERED_WHEN_LOGIN);
  localStorage.removeItem(TOKEN_LAST_VISITED_URL);
  localStorage.removeItem(TOKEN_LAST_VISITED_EMAIL);
  localStorage.clear();
}


// ==========================
// ====   color / theme
// ==========================

export const toContrastColor = (color: string /* #ffffff or rgb(...) or rgba(...) format*/) => {
  // Convert the color to RGB then invert it
  const c = tinycolor2(color);
  if (!c.isValid()) {
    console.error(`invalid color format ${color}`);
  }
  const rgb = tinycolor2(color).toRgb();
  const invertedRgb = {
    r: 255 - rgb.r,
    g: 255 - rgb.g,
    b: 255 - rgb.b,
  };

  // Convert back to hex for use
  const toColor = tinycolor2(invertedRgb);
  return fromTinyColor2ToString(toColor, c.getFormat());
}

const fromTinyColor2ToString = (c: tinycolor2.Instance, format: string = 'hex'): string => {
  if (format == 'rgb') {
    return c.toRgbString();
  }
  return c.toHexString();
}


export const generateMaterialShades = (baseColor: string): MaterialShades => {
  const color = tinycolor2(baseColor);
  if (!color.isValid()) {
    console.error(`basecolor ${baseColor} is an invalid color`);
  }
  const shades = {
    '50': fromTinyColor2ToString(tinycolor2(color.toHex()).lighten(52)),
    '100': fromTinyColor2ToString(tinycolor2(color.toHex()).lighten(37)),
    '200': fromTinyColor2ToString(tinycolor2(color.toHex()).lighten(26)),
    '300': fromTinyColor2ToString(tinycolor2(color.toHex()).lighten(12)),
    '400': fromTinyColor2ToString(tinycolor2(color.toHex()).lighten(6)),
    '500': fromTinyColor2ToString(tinycolor2(color.toHex())), // base color
    '600': fromTinyColor2ToString(tinycolor2(color.toHex()).darken(6)),
    '700': fromTinyColor2ToString(tinycolor2(color.toHex()).darken(12)),
    '800': fromTinyColor2ToString(tinycolor2(color.toHex()).darken(18)),
    '900': fromTinyColor2ToString(tinycolor2(color.toHex()).darken(24)),
    'A100': fromTinyColor2ToString(tinycolor2(color.toHex()).lighten(50).saturate(30)),
    'A200': fromTinyColor2ToString(tinycolor2(color.toHex()).lighten(30).saturate(30)),
    'A400': fromTinyColor2ToString(tinycolor2(color.toHex()).lighten(10).saturate(15)),
    'A700': fromTinyColor2ToString(tinycolor2(color.toHex()).darken(5).saturate(10)),
  };

  return shades;
}

export const generateWhitelabelStyles = (options: {
  primary: string,
    accent: string,
    warn: string,
}): WhitelabelStyles => {
  const primaryContrast = toContrastColor(options.primary);
  const accentContrast = toContrastColor(options.accent);
  const warnContrast = toContrastColor(options.warn);
  const primaryShades = generateMaterialShades(options.primary);
  const primaryContrastShades = generateMaterialShades(primaryContrast);
  const accentShades = generateMaterialShades(options.accent);
  const accentContrastShades = generateMaterialShades(accentContrast);
  const warnShades = generateMaterialShades(options.warn);
  const warnContrastShades = generateMaterialShades(warnContrast);
  return {
    'grow-primary-palette-50': primaryShades['50'],
    'grow-primary-palette-100': primaryShades['100'],
    'grow-primary-palette-200': primaryShades['200'],
    'grow-primary-palette-300': primaryShades['300'],
    'grow-primary-palette-400': primaryShades['400'],
    'grow-primary-palette-500': primaryShades['500'],
    'grow-primary-palette-600': primaryShades['600'],
    'grow-primary-palette-700': primaryShades['700'],
    'grow-primary-palette-800': primaryShades['800'],
    'grow-primary-palette-900': primaryShades['900'],
    'grow-primary-palette-A100': primaryShades.A100,
    'grow-primary-palette-A200': primaryShades.A200,
    'grow-primary-palette-A400': primaryShades.A400,
    'grow-primary-palette-A700': primaryShades.A700,
    'grow-primary-palette-contrast-50': primaryContrastShades['50'],
    'grow-primary-palette-contrast-100': primaryContrastShades['100'],
    'grow-primary-palette-contrast-200': primaryContrastShades['100'],
    'grow-primary-palette-contrast-300': primaryContrastShades['100'],
    'grow-primary-palette-contrast-400': primaryContrastShades['100'],
    'grow-primary-palette-contrast-500': primaryContrastShades['100'],
    'grow-primary-palette-contrast-600': primaryContrastShades['100'],
    'grow-primary-palette-contrast-700': primaryContrastShades['100'],
    'grow-primary-palette-contrast-800': primaryContrastShades['100'],
    'grow-primary-palette-contrast-900': primaryContrastShades['100'],
    'grow-primary-palette-contrast-A100': primaryContrastShades.A100,
    'grow-primary-palette-contrast-A200': primaryContrastShades.A200,
    'grow-primary-palette-contrast-A400': primaryContrastShades.A400,
    'grow-primary-palette-contrast-A700': primaryContrastShades.A700,

    'grow-accent-palette-50': accentShades['50'],
    'grow-accent-palette-100': accentShades['100'],
    'grow-accent-palette-200': accentShades['200'],
    'grow-accent-palette-300': accentShades['300'],
    'grow-accent-palette-400': accentShades['400'],
    'grow-accent-palette-500': accentShades['500'],
    'grow-accent-palette-600': accentShades['600'],
    'grow-accent-palette-700': accentShades['700'],
    'grow-accent-palette-800': accentShades['800'],
    'grow-accent-palette-900': accentShades['900'],
    'grow-accent-palette-A100': accentShades.A100,
    'grow-accent-palette-A200': accentShades.A200,
    'grow-accent-palette-A400': accentShades.A400,
    'grow-accent-palette-A700': accentShades.A700,
    'grow-accent-palette-contrast-50': accentContrastShades['50'],
    'grow-accent-palette-contrast-100': accentContrastShades['100'],
    'grow-accent-palette-contrast-200': accentContrastShades['200'],
    'grow-accent-palette-contrast-300': accentContrastShades['300'],
    'grow-accent-palette-contrast-400': accentContrastShades['400'],
    'grow-accent-palette-contrast-500': accentContrastShades['500'],
    'grow-accent-palette-contrast-600': accentContrastShades['600'],
    'grow-accent-palette-contrast-700': accentContrastShades['700'],
    'grow-accent-palette-contrast-800': accentContrastShades['800'],
    'grow-accent-palette-contrast-900': accentContrastShades['900'],
    'grow-accent-palette-contrast-A100': accentContrastShades.A100,
    'grow-accent-palette-contrast-A200': accentContrastShades.A200,
    'grow-accent-palette-contrast-A400': accentContrastShades.A400,
    'grow-accent-palette-contrast-A700': accentContrastShades.A700,

    'grow-warn-palette-50': warnShades['50'],
    'grow-warn-palette-100': warnShades['100'],
    'grow-warn-palette-200': warnShades['200'],
    'grow-warn-palette-300': warnShades['300'],
    'grow-warn-palette-400': warnShades['400'],
    'grow-warn-palette-500': warnShades['500'],
    'grow-warn-palette-600': warnShades['600'],
    'grow-warn-palette-700': warnShades['700'],
    'grow-warn-palette-800': warnShades['800'],
    'grow-warn-palette-900': warnShades['900'],
    'grow-warn-palette-A100': warnShades.A100,
    'grow-warn-palette-A200': warnShades.A200,
    'grow-warn-palette-A400': warnShades.A400,
    'grow-warn-palette-A700': warnShades.A700,
    'grow-warn-palette-contrast-50': warnContrastShades['50'],
    'grow-warn-palette-contrast-100': warnContrastShades['100'],
    'grow-warn-palette-contrast-200': warnContrastShades['200'],
    'grow-warn-palette-contrast-300': warnContrastShades['300'],
    'grow-warn-palette-contrast-400': warnContrastShades['400'],
    'grow-warn-palette-contrast-500': warnContrastShades['500'],
    'grow-warn-palette-contrast-600': warnContrastShades['600'],
    'grow-warn-palette-contrast-700': warnContrastShades['700'],
    'grow-warn-palette-contrast-800': warnContrastShades['800'],
    'grow-warn-palette-contrast-900': warnContrastShades['900'],
    'grow-warn-palette-contrast-A100': warnContrastShades.A100,
    'grow-warn-palette-contrast-A200': warnContrastShades.A200,
    'grow-warn-palette-contrast-A400': warnContrastShades.A400,
    'grow-warn-palette-contrast-A700': warnContrastShades.A700,
  };
}





// ==========================
// ====   UX Unit Conversion
// ==========================

export const pxToRem = (pixel: number, base: number = 14) => {
  return `${(pixel / base)}rem`;
}

export const remToPx = (rem: number, base: number = 14) => {
  return rem * base;
}


// ==========================
// ====   CSS Variables
// ==========================
export const getCssVariableContent = (cssVariable: string): string => {
  const style = getComputedStyle(document.documentElement);
  let value = style.getPropertyValue(cssVariable);
  if (!value) { // attempt to get it from body
    const body = document.querySelector('body');
    if (body) {
      const bodyStyle = getComputedStyle(body);
      value =  bodyStyle.getPropertyValue(cssVariable);
    }
  }
  return value;
}

export const hexToRgb = (hex: string, alpha?: number): string => {
  const {red, green, blue} = hexRgb(hex);
  if (_.isNil(alpha)) {
    return `rgb(${red}, ${green}, ${blue})`;
  } else {
    return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
  }
}


// ==========================
// ====   Stepper utils
// ==========================
// NOTE: DEPRECATED see ApplicationStepper2Component
export const setNextButtonText = (stepFormGroup: FormGroup, nextStepButtonText: string) => {
  (stepFormGroup as any)['nextStepButtonText'] = nextStepButtonText;
}

// NOTE: DEPRECATED see ApplicationStepper2Component
export const setSecondaryButtonText = (stepFormGroup: FormGroup, nextStepButtonText: string) => {
  (stepFormGroup as any)['secondaryButtonText'] = nextStepButtonText;
}

// NOTE: DEPRECATED see ApplicationStepper2Component
export const setCurrentStepFn = (stepFormGroup: FormGroup, fn: (e: StepperSelectionEvent)=>void) => {
  (stepFormGroup as any)['currentStepFn'] = fn;
}

// NOTE: DEPRECATED see ApplicationStepper2Component
export const setSubmitStepFn = (stepFormGroup: FormGroup, fn: (s: CdkStepper)=>void) => {
  (stepFormGroup as any)['submitStepFn'] = fn;
}

// NOTE: DEPRECATED see ApplicationStepper2Component
export const setSecondaryButtonFn = (stepFormGroup: FormGroup, fn: (s: CdkStepper)=>void) => {
  (stepFormGroup as any)['secondaryButtonFn'] = fn;
}

// NOTE: DEPRECATED see ApplicationStepper2Component
export const setPrevNextButtonVisibilityFn = (stepFormGroup: FormGroup, prevVisible: boolean, nextVisible: boolean) => {
  (stepFormGroup as any)['prevStepButtonVisible'] = prevVisible;
  (stepFormGroup as any)['nextStepButtonVisible'] = nextVisible;
}


// ==========================
// ====   Field Mask
// ==========================

export const createYearInputMask = () => {
  return createMask({ mask: '9999' });
}

export const createAbnInputMask = () => {
  return createMask({ mask: '99999999999' });
}

export const createAcnInputMask = () => {
  return createMask({ mask: '999999999'});
}

// NOTE: not used
// export const createAbnOrAcnInputMask = () => {
//   return createMask({
//     regex: '^[0-9]{9,30}$',
//     allowMinus: false,
//     rightAlign: false,
//     greedy: true,
//     placeholder:'',
//   });
// }

export const createBsbInputMask = () => {
  return createMask({mask: '999999'});
}

export const createPostcodeInputMask = () => {
  return createMask({mask: '9999'});
}

export const createAccountNumberInputMask = () => {
  return createMask({
    // mask: '9999999999',
    regex: '^[0-9]{1,10}$',
    allowMinus: false,
    // digits: 0,
    rightAlign: false,
    greedy: true,
    placeholder:'',
  });
}

export const createNameInputMask = () => {
  return createMask({
    regex: '^[a-zA-Z-\'\\s]{2,30}$',
    placeholder: '',
  })
}

export const createNameIncludeDigitsInputMask = () => {
  return createMask({
    regex: '^[0-9a-zA-Z-\'\\s]{2,30}$',
    placeholder: '',
  })
}

export const createDriverLicenceNumberInputMask = () => {
  return createMask({
    regex: '^[a-zA-Z0-9]{6,10}$',
    allowMinus: false,
    rightAlign: false,
    greedy: true,
    placeholder:'',
  })
}

export const createDriverLicenceCardNumberInputMask = () => {
  return createMask({
    regex: '^[a-zA-Z0-9]{6,10}$',
    allowMinus: false,
    rightAlign: false,
    greedy: true,
    placeholder:'',
  })
}

export const createCurrencyInputMask = () => {
  return createMask({
    alias: 'numeric',
    groupSeparator: ',',
    digits: 2,
    digitsOptional: false,
    rightAlign: false,
    // placeholder: '0',
  });
}

export const createNoDecimalInputMask = () => {
  return createMask({
    alias: 'numeric',
    digits: 0,
    digitsOptional: false,
    rightAlign: false,
  })
}

export const createTwoDecimalInputMask = () => {
  return createMask({
    alias: 'numeric',
    digits: 2,
    digitsOptional: false,
    rightAlign: false,
  })
}

export const createThreeDecimalInputMask = () => {
  return createMask({
    alias: 'numeric',
    digits: 3,
    digitsOptional: false,
    rightAlign: false,
  })
}

// NOTE: not used
// export const createEightDecimalInputMask = () => {
//   return createMask({
//     alias: 'numeric',
//     digits: 8,
//     digitsOptional: false,
//     rightAlign: false,
//   })
// }

// NOTE: not used
// export const createMobileNumberInputMask = () => {
//   return createMask({
//     // alias: 'numeric',
//     // mask: '9999999999',
//     regex: '0[0-9]{9,9}',
//     allowMinus: false,
//     digits: 0,
//     rightAlign: false,
//     greedy: true,
//   })
// }

export const createMobilePhoneNumberInputMask  = () => {
  return createMask({
    // alias: 'numeric',
    // mask: '9999999999',
    regex: '04[0-9]{8,8}',
    allowMinus: false,
    digits: 0,
    rightAlign: false,
    greedy: true,
    onBeforePaste: function (pastedValue, opts) {
      const v = (pastedValue ?? '').trim();
      if (v.startsWith('+61')) {
        return v.replace('+61', '0');
      } else if (v.startsWith('61')) {
        return v.replace('61', '0');
      }
      return pastedValue;
    },
  })
}

export const createHexColorInputMask = () => {
  return createMask({
    regex:'#[0-9A-Fa-f]{6}',
    allowMinus: false,
    rightAlign: false,
    greedy: true,
  });
}

export const createHexOrRgbColorInputMask = () => {
  const decimalRegex = `[0-1](?:\\.[0-9])?`;
  const numRegex = `[0-9]{1,3},[0-9]{1,3},[0-9]{1,3}`;
  const hexRegex = `#[0-9A-Fa-f]{6}`;
  const rgbRegex = `rgb\\(${numRegex}\\)`;
  const rgbaRegex = `rgba\\(${numRegex},${decimalRegex}\\)`;
  return createMask({
    regex: `${rgbaRegex}|${rgbRegex}|${hexRegex}`,
    greedy: false,
  });
}

export const createUrlInputMask = () => {
  return createMask({
    regex:'http(s)*://[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}([-a-zA-Z0-9()@:%_\+.~#?&//=]*)',
  })
}

export const createPhoneNumberInputMask = () => {
  return createMask({
    regex: '0[23478][0-9]{8,8}',
    allowMinus: false,
    digits: 0,
    rightAlign: false,
    greedy: true,
  })
}

export const createEmailInputMask = () => {
  return createMask({alias: 'email'});
}

export const createDateInputMask = () => {
  return createMask({
    alias: 'datetime',
    inputFormat: 'dd/mm/yyyy',
    parser: (value: any) => {
      if (typeof value === 'string') {
        const m = moment(value, 'DD/MM/YYYY', true);
        if (m.isValid()) {
          return m;
        }
      }
      if (moment.isMoment(value)) {
        return moment(value);
      }
      return null;
    },
  })
}

export const createBillerCodeMask = () => {
  return createMask({
    regex: '^[0-9]{4,6}$',
    allowMinus: false,
    digits: 0,
    rightAlign: false,
  })
}

export const createBpayCRNMask = () => {
  return createMask({
    regex: '^[0-9]{2,20}$',
    allowMinus: false,
    digits: 0,
    rightAlign: false,
  })
}

// export const createNameInputMask = () => {
//   return createMask({
//     regex: '^[a-zA-Z-\'\\s]{2,30}$',
//     placeholder: '',
//   })
// }



/// =================================
// ===== FormGroup / FormControl utils
// =======================================
export const findInvalidControlsRecursive = (formToInvestigate: FormGroup | FormArray): string[] => {
  const invalidControls: string[] = [];
  const recursiveFunc = (form: FormGroup|FormArray) => {
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      if (control) {
        if (control.invalid) {
          invalidControls.push(field);
        }
        if (control instanceof FormGroup) {
          recursiveFunc(control);
        } else if (control instanceof FormArray) {
          recursiveFunc(control);
        }
      }
    });
  };
  recursiveFunc(formToInvestigate);
  return invalidControls;
};

export const markTriggerSubject = (stepControl: FormGroup): Subject<boolean> => {
  if (!(stepControl as any)['markTrigger']) {
    (stepControl as any)['markTrigger'] = new Subject();
  }
  return  (stepControl as any)['markTrigger'];
}

// find AbstractControl errors
export const doMarkAll = (a: AbstractControl, errors: string[] = [], k: string | null = null) => {
  // this.markTrigger.next(true);
  doMark(a);
  if (a instanceof FormGroup) {
    for (const key of Object.keys(a.controls)) {
      const c = a.controls[key];
      doMarkAll(c, errors, `${k ?? ''}${key}`);
    }
  } else if (a instanceof FormArray) {
    let i = 0;
    for (const c of a.controls) {
      doMarkAll(c, errors, `${k ?? ''} ${i}`);
      i++;
    }
  } else {
    if (a.errors) {
      errors.push(`${k} ${Object.keys(a.errors)}`);
    }
  }
  return errors;
}

export const doMark = (a: AbstractControl) => {
  a.markAllAsTouched();
  a.markAsDirty();
  a.updateValueAndValidity({emitEvent: false});
}



// =====================================================================================================================================
////////////////////////  Component related misc helper functions  //////////////////////////////////////////////////////
// =====================================================================================================================================
export const isValidAssetProductType = (productType: string): productType is AssetFinanceProductType => {
  return ['FAST DOC' , 'EASY DOC' , 'FULL DOC', ].includes(productType);
}

export const isValidBoolean = (b: string): boolean => {
  return ['true', 'false'].includes((b ?? '').toLowerCase());
}

export const applicantsThatAreIndividual = (applicant: ApplicationApplicant):
  (IndividualTrustee | IndividualMember | IndividualPartner | Exclude<SoleTraderValue, null> | Exclude<DirectorValue, null>[number])[] => {
  const r: (IndividualTrustee | IndividualMember | IndividualPartner | Exclude<SoleTraderValue, null> | Exclude<DirectorValue, null>[number])[] = [];
  if (applicant) {
    if (Array.isArray(applicant)) {
      for (const app of applicant) {
        switch (app.kind) {
          case 'Director': {
            r.push(app);
            break;
          }
          case 'Member': {
            if (app.type === 'Individual') {
              r.push(app);
            }
            break;
          }
          case 'Partner': {
            if (app.type === 'Individual') {
              r.push(app);
            }
            break;
          }
          case 'Trustee': {
            if (app.type === 'Individual') {
              r.push(app);
            }
            break;
          }
        }
      }
    } else if (applicant.kind === 'SoleTrader') {
      r.push(applicant);
    }
  }
  return r;
}

export const applicantsThatAreEntity = (applicant: ApplicationApplicant):
  (EntityTrustee | EntityMember | EntityPartner)[] => {

  const r: (EntityTrustee | EntityMember | EntityPartner)[] = [];
  if (applicant) {
    if (Array.isArray(applicant)) {
      for (const app of applicant) {
        switch(app.kind) {
          case 'Member': {
            if (app.type === 'Entity') {
              r.push(app);
            }
            break;
          }
          case 'Trustee': {
            if (app.type === 'Entity') {
              r.push(app);
            }
            break;
          }
          case 'Partner': {
            if (app.type === 'Entity') {
              r.push(app);
            }
            break;
          }
        }
      }
    }
  }
  return r;
}

export const guarantorsThatAreEntity = (guarantors: Exclude<GuarantorValue, null>): EntityGuarantor[] => {
  const r: EntityGuarantor[] = [];
  for (const guarantor of guarantors) {
    if (guarantor.type === 'Entity') {
      r.push(guarantor);
    }
  }
  return r;
}

export const guarantorsThatAreIndividual = (guarantors: Exclude<GuarantorValue, null>): IndividualGuarantor[] => {
  const r: IndividualGuarantor[] = [];
  for (const guarantor of guarantors) {
    if (guarantor.type === 'Individual') {
      r.push(guarantor);
    }
  }
  return r;
}

export const toInteflowGender = (gender: GenderValue): 'M' | 'F' | undefined => {
  if (gender === 'Male') {
    return 'M';
  } else if (gender === 'Female') {
    return 'F';
  }
  return undefined;
}

export const toInteflowTimeAtAddress = (t: TimeInAddressValue) => {
  if (t) {
    return ((t.years ?? 0) * 12) + (t.months ?? 0);
  }
  return undefined;
}

export const toInteflowPropertyOwnerAddress = (propertyOwner: PropertyOwnerWithAddressValue) => {
  if (propertyOwner && propertyOwner.address) {
    return toInteflowAddress(propertyOwner.address);
  }
  return undefined;
}

export const toInteflowAddress = (address: Address2ComponentValue) => {
  if (address && address.address) {
    return {
      UnformattedAddress: address.address,
      StreetNumber: address.StreetNumber,
      StreetName: address.StreetName,
      StreetType: address.StreetType,
      Suburb: address.Suburb,
      State: address.State,
      Postcode: address.Postcode,
      UnitNumber: address.UnitNumber,
      IsManualInput: false,
    }
  }
  return undefined;
}

export const toInteflowPreviousAddress = (t: TimeInAddressValue) => {
  if (t && t.previousAddress && t.previousAddress.address) {
    return {
      UnformattedAddress: t.previousAddress.address,
      StreetNumber: t.previousAddress?.StreetNumber,
      StreetName: t.previousAddress?.StreetName,
      StreetType: t.previousAddress?.StreetType,
      Suburb: t.previousAddress?.Suburb,
      State: t.previousAddress?.State,
      Postcode: t.previousAddress?.Postcode
    }
  }
  return undefined;
}

export const toInteflowTimeAtCurrentEmployer = (e: EmployerValue) => {
  if (e) {
    return ((e.monthsAtCurrentEmployer ?? 0)) + ((e.yearsAtCurrentEmployer ?? 0) * 12);
  }
  return undefined;
}

export const individualGarantors = (guarantors: GuarantorValue): IndividualGuarantor[] => {
  if (guarantors) {
    return guarantors.filter(g => g.type === 'Individual') as IndividualGuarantor[];
  }
  return [];
}

export const applicantsThatAreGuarantor = (applicant: ApplicationApplicant): IndividualGuarantor[] => {
  const guarantors: IndividualGuarantor[] = []
  if (applicant) {
    if (Array.isArray(applicant)) {
      for (const app of applicant) {
        switch(app.kind) {
          case 'Director': {
            const director: Exclude<DirectorValue, null>[number] = app;
            if (director.guarantor) {
              const individualGuarantor: IndividualGuarantor = {
                type: 'Individual',
                kind: director.type as any,
                title: director.title,
                firstName: director.firstName,
                lastName: director.lastName,
                personalAssets: director.personalAssets,
                personalLiabilities: director.personalLiabilities,
                propertyOwner: director.propertyOwner,
                residentialAddress: director.residentialAddress,
                dob: director.dob,
                privacyConsentObtained: director.privacyConsentObtained ?? false,
                gender: director.gender,
                email: director.email,
                mobileNumber: director.mobileNumber,
                middleName: ''
              }
              guarantors.push(individualGuarantor as any);
            }
            break;
          }
          case 'Partner': {
            const partner: Exclude<PartnerValue, null>[number] = app;
            if (partner.type === 'Individual' && partner.guarantor) {
              const individualGuarantor: IndividualGuarantor = {
                type: 'Individual',
                kind: 'WithPropertyAddress',
                title: partner.title,
                firstName: partner.firstName,
                lastName: partner.lastName,
                personalAssets: partner.personalAssets,
                personalLiabilities: partner.personalLiabilities,
                propertyOwner: partner.propertyOwner,
                residentialAddress: partner.residentialAddress,
                dob: partner.dob,
                privacyConsentObtained: partner.privacyConsentObtained ?? false,
                gender: partner.gender,
                email: partner.email,
                mobileNumber: partner.mobileNumber,
                middleName: ''
              }
              guarantors.push(individualGuarantor);
            }
            break;
          }
          case 'Member': {
            const member: Exclude<MemberValue, null>[number] = app;
            if (member.type === 'Individual' && member.guarantor) {
              const individualGuarantor: IndividualGuarantor = {
                type: 'Individual',
                kind: 'WithPropertyAddress',
                title: member.title,
                firstName: member.firstName,
                lastName: member.lastName,
                personalAssets: member.personalAssets,
                personalLiabilities: member.personalLiabilities,
                propertyOwner: member.propertyOwner,
                residentialAddress: member.residentialAddress,
                dob: member.dob,
                privacyConsentObtained: member.privacyConsentObtained ?? false,
                gender: member.gender,
                email: member.email,
                mobileNumber: member.mobileNumber,
                middleName: ''
              }
              guarantors.push(individualGuarantor);
            }

            break;
          }
          case 'Trustee': {
            const trustee: Exclude<TrusteeValue, null>[number] = app;
            if (trustee.type === 'Individual' && trustee.guarantor) {
              const individualGuarantor: IndividualGuarantor = {
                type: 'Individual',
                kind: 'WithPropertyAddress',
                title: trustee.title,
                firstName: trustee.firstName,
                lastName: trustee.lastName,
                personalAssets: trustee.personalAssets,
                personalLiabilities: trustee.personalLiabilities,
                propertyOwner: trustee.propertyOwner,
                residentialAddress: trustee.residentialAddress,
                dob: trustee.dob,
                privacyConsentObtained: trustee.privacyConsentObtained ?? false,
                gender: trustee.gender,
                email: trustee.email,
                mobileNumber: trustee.mobileNumber,
                middleName: ''
              }
              guarantors.push(individualGuarantor);
            }
            break;
          }
        }
      }
    } else if (applicant.kind === 'SoleTrader') {
      // Sole Trader
      const soletrader: Exclude<SoleTraderValue, null> = applicant;
      if (soletrader.guarantor) {
        const individualGuarantor: IndividualGuarantor = {
          type: 'Individual',
          kind: 'WithPropertyAddress',
          title: soletrader.title,
          firstName: soletrader.firstName,
          lastName: soletrader.lastName,
          personalAssets: null,
          personalLiabilities: null,
          propertyOwner: soletrader.propertyOwner ?? false,
          residentialAddress: soletrader.residentialAddress,
          dob: soletrader.dob,
          privacyConsentObtained: soletrader.privacyConsentObtained ?? false,
          gender: soletrader.gender,
          email: soletrader.email,
          mobileNumber: soletrader.mobile,
          middleName: ''
        }
        guarantors.push(individualGuarantor);
      }
    }
  }
  return guarantors;
}

export const categoriesApplicants = (
  // todo: temporary fix
  applicants: ApplicationApplicant
    // | (Exclude<TrusteeValue, null>[number] | Exclude<MemberValue, null>[number] | Exclude<PartnerValue, null>[number] | Exclude<DirectorValue, null>[number] | Exclude<SoleTraderValue, null>)[]
  ): {
    trustees: Exclude<TrusteeValue, null>[number][],
    members: Exclude<MemberValue, null>[number][],
    partners: Exclude<PartnerValue, null>[number][],
    directors: Exclude<DirectorValue, null>[number][],
    soletraders: Exclude<SoleTraderValue, null>[],
  } => {

  const directors: Exclude<DirectorValue, null> = [];
  const members: Exclude<MemberValue, null> = [];
  const partners: Exclude<PartnerValue, null> = [];
  const trustees: Exclude<TrusteeValue, null> = [];
  const soletraders: Exclude<SoleTraderValue, null>[] = [];
  if (applicants) {
    let app: any = applicants
    if (app.kind === 'SoleTrader') {
      const soleTrader: Exclude<SoleTraderValue, null> = app;
      soletraders.push(soleTrader);
    } else if (Array.isArray(applicants)) {
      for (const app of applicants) {
        switch (app.kind) {
          case 'Director': {
            const director: Exclude<DirectorValue, null>[number] = app;
            directors.push(director);
            break;
          }
          case 'Partner': {
            const partner: Exclude<PartnerValue, null>[number] = app;
            partners.push(partner);
            break;
          }
          case 'Member': {
            const member: Exclude<MemberValue, null>[number] = app;
            members.push(member);
            break;
          }
          case 'Trustee': {
            const trustee: Exclude<TrusteeValue, null>[number] = app;
            trustees.push(trustee);
            break;
          }
        }
      }
    }
  }
  return {
    directors, trustees, members, partners, soletraders
  };
}



// =======================================================================================================================================
// ====  Convert / Mapping :  Application to component values
// =======================================================================================================================================
export const toEntityTypeValue = (type: EntityType): EntityTypeValue => {
  if (type === 'P/L') {
    return EntityTypeOptions.find(opt => opt.type === 'company') ?? null;
  } else if (type === 'PTNR') {
    return EntityTypeOptions.find(opt => opt.type === 'partnership') ?? null;
  } else if (type === 'SLTR') {
    return EntityTypeOptions.find(opt => opt.type === 'sole-trader') ?? null;
  } else if (type === 'PTY') {
    return EntityTypeOptions.find(opt => opt.type === 'company') ?? null;
  } else if (type === 'TRST') {
    return EntityTypeOptions.find(opt => opt.type === 'trust') ?? null;
  }
  return null;
}

export const toEntityType = (entityType: EntityTypeValue): EntityType => {
  if (entityType) {
    switch(entityType.type) {
      case 'company': {
        return 'PTY';
      }
      case 'trust': {
        return 'TRST';
      }
      case 'partnership': {
        return 'PTNR';
      }
      case 'sole-trader': {
        return 'SLTR';
      }
      case 'other': {
        return 'PTY';
      }
      default: {
        return 'PTY';
      }
    }
  }
  return null;
}

export const toInteflowLegalName = (b: BusinessSearchValue) : string | null => {
  if (b && b.type === 'search-result' && b.result && b.result.entityType) {
    if (b.result.entityType.entityTypeCode === 'IND') { // sole trader
      if (Array.isArray(b.result.legalName) && b.result.legalName.length) {
        return `${b.result.legalName[0].givenName} ${b.result.legalName[0].familyName}`;
      } else {
        if (b.result.legalName) {
          return `${(b.result.legalName as any).givenName} ${(b.result.legalName as any).familyName}`;
        }
      }
    }
  }
  if (b && b.type === 'search-result' &&  b.result) {
    return b.result.organisationName;
  }
  return b?.organisationName ?? '';
}

export const toInteflowEntityTypes = (entityType: EntityTypes): 'TRST' | 'PTNR' | 'PTY' | 'SLTR' => {
  if (entityType === 'sole-trader') {
    return 'SLTR';
  } else if (entityType === 'partnership') {
    return 'PTNR';
  } else if (entityType === 'company') {
    return 'PTY';
  } else if (entityType === 'trust') {
    return 'TRST';
  }
  return 'PTY';
}

// NOTE: moved to shared-library application-details.utils.ts
// export const primaryCommercialEntity = (application: Application): CommercialEntity | null => {
//   if (application && application.CommercialEntities && application.CommercialEntities.length) {
//     const primaryCommercialEntity = (application.CommercialEntities).find((comm: CommercialEntity) => (comm).Type === 'Primary');
//     return primaryCommercialEntity ? primaryCommercialEntity : null;
//   }
//   return null;
// }
//
// export const applicantIndividual = (application: Application): Individual | null => {
//   if (application && application.Individuals && application.Individuals.length) {
//     const applicationIndividual = (application.Individuals).find((ind: Individual) => ind.Role === 'Applicant');
//     return applicationIndividual ?? null;
//   }
//   return null;
// }

const assetsFromIndividual = (individual: IndividualWithAssetsAndLiabilities): PersonalAsset[] => {
  const assets: PersonalAsset[] = [];
  if (individual.Assets) {
    (individual.Assets ?? []).forEach((a ) => {
      const t = assetTypesOptions.find(opt => opt.type === a.AssetType);
      if (t) {
        if (t.type === 'property') {
          const add: Address = (a as IndividualPropertyAsset).Address;
          assets.push({
            assetType: t,
            value: Number(a.Value ?? 0),
            // address: {address: (a as { Address: { UnformattedAddress: string } }).Address?.UnformattedAddress ?? ''},
            address: {
              address: add.UnformattedAddress,
              StreetNumber: add.StreetNumber,
              StreetName: add.StreetName,
              StreetType: add.StreetType,
              Suburb: add.Suburb,
              State: add.State,
              UnitNumber: add.UnitNumber,
              Postcode: add.Postcode,
            },
          });
        } else {
          assets.push({
            assetType: t,
            value: Number(a.Value ?? 0),
            description: (a as { Description: string }).Description,
          });
        }
      }
    });
  }
  return assets;
}

const liabilitiesFromIndividual = (individual: IndividualWithAssetsAndLiabilities): PersonalLiability[] => {
  const liabilities: PersonalLiability[] = [];
  if(individual.Liabilities) {
    (individual.Liabilities).forEach((a) => {
      const t = liabilityTypesOptions.find(opt => opt.type === a.Type);
      if (t) {
        if (t.type === 'mortgage') {
          const add: Address = (a  as IndividualMortgageLiability).Address;
          liabilities.push({
            liabilityType: t,
            value: Number(a.Value ?? 0),
            // address: {address: (a as { Address: { UnformattedAddress: string } }).Address?.UnformattedAddress ?? ''},
            address: {
              address: add.UnformattedAddress,
              StreetNumber: add.StreetNumber,
              StreetName: add.StreetName,
              StreetType: add.StreetType,
              Suburb: add.Suburb,
              State: add.State,
              UnitNumber: add.UnitNumber,
              Postcode: add.Postcode,
            },
          });
        } else {
          liabilities.push({
            liabilityType: t,
            value: Number(a.Value ?? 0),
            financier: (a as { Financier: string }).Financier,
          });
        }
      }
    });
  }
  return liabilities;
}

export const applicationToLegalName = (application: Application): string | null=> {
  if (application) {
    const commercialEntity = primaryCommercialEntity(application);
    if (commercialEntity) {
      return commercialEntity.LegalName;
    }
  }
  return null;
}

export const applicationToBusinessSearchValue = (application: Application): BusinessSearchValue => {
  if (application) {
    const commercialEntity = primaryCommercialEntity(application);
    if (commercialEntity) {
      if (application.CompanyDetails) {
          return {
              type: 'search-result',
              organisationName: commercialEntity.LegalName,
              status: '',
              abn: application.CommercialEntities[0].ABN,
              acn: application.CommercialEntities[0].ACN,
              postcode: '',
              stateCode: '',
              entityTypeValue: toEntityTypeValue(commercialEntity.EntityType),
              result: application.CompanyDetails ?? undefined,
          };
      }
      return {
         type: 'free-text',
         organisationName: commercialEntity.LegalName,
         abn: application.CommercialEntities[0].ABN,
         acn: application.CommercialEntities[0].ACN,
      };
    }
  }
  return null;
}

export const applicationToDirectSalesUserFirstName  = (application: Application): string | null=> {
  if (application) {
    switch(application.ApplicationType) {
      case 'AssetFinance':
      case 'BusinessLoans':
      case 'BusinessOverdraft':
        return application.DirectSalesUserFirstName ?? '';
    }
  }
  return null;
}

export const applicationToDirectSalesUserLastName = (application: Application): string | null=> {
  if (application) {
    switch(application.ApplicationType) {
      case 'AssetFinance':
      case 'BusinessLoans':
      case 'BusinessOverdraft':
        return application.DirectSalesUserLastName ?? '';
    }
  }
  return null;
}

export const applicationToDirectSalesUserEmail = (application: Application): string | null=> {
  if (application) {
    switch(application.ApplicationType) {
      case 'AssetFinance':
      case 'BusinessLoans':
      case 'BusinessOverdraft':
        return application.DirectSalesUserEmail ?? '';
    }
  }
  return null;
}

export const applicationToPreviousBusiness = (application: Application): any => {
  if (application && application.ApplicationType !== 'InsurancePremium' && application.ApplicationType !== 'Consumer') {
    return application.PreviousBusiness;
  }
  return null;
}

export const applicationToAdditionalBrokerCorrespondent = (application: Application): UserSelectionValue => {
  if (application) {
    return application.AdditionalBrokerCorrespondent;
  }
  return null;
}

export const applicationToBrokerContact = (application: Application): SalesforceContactSameAccountSelectionValue => {
  if (application) {
    return application.BrokerContact;
  }
  return null;
}

export const applicationToHasAdditionalBrokerCorrespondent = (application: Application): boolean => {
  if (application) {
    return !!application.AdditionalBrokerCorrespondent;
  }
  return false;
}

export const applicationToBrokerSearchValue = (application: Application): AggregatorSearchComponentValue => {
  if(application) {
    if (application.AppInfo &&
      application.AppInfo.BrokerSalesforceID &&
      application.AppInfo.BrokerAbn &&
      application.AppInfo.BrokerEntityName) {
      return {
        salesforceId: application.AppInfo.BrokerSalesforceID,
        abn: application.AppInfo.BrokerAbn,
        entityName: application.AppInfo.BrokerEntityName,
      }
    }
    return {
      abn: application.BrokerABN,
      salesforceId: application.BrokerSalesforceId,
      entityName: application.BrokerName,
    }
  }
  return null;
}

export const applicationToInvoiceAmount = (application: Application): number | null => {
  if (application) {
    switch(application.ApplicationType) {
      case 'AssetFinance':
      case 'BusinessLoans':
      case 'BusinessOverdraft':
      case 'Consumer':
      case 'Commercial':
        return application.PricingDetails.InvoiceAmount;
      case 'TradeFinance':
      case 'CorporateLoans':
      case 'InsurancePremium':
        return null;
    }
  }
  return null;
}

export const applicationToDepositAmount = (application: Application): number | null => {
  if (application) {
    switch(application.ApplicationType) {
      case 'AssetFinance':
      case 'Commercial':
        return application.PricingDetails.Deposit;
      case 'BusinessLoans': // not used in bussiness loan
      case 'BusinessOverdraft':
        return null;
      case 'Consumer':
        return application.PricingDetails.Deposit;
      case 'TradeFinance':
      case 'CorporateLoans':
      case 'InsurancePremium':
        return application.PricingDetails.Deposit;
    }
  }
  return null;
}

export const applicationToLoanAmount = (application: Application): number | null => {
  if (application) {
    switch(application.ApplicationType) {
      case 'AssetFinance':
      case 'BusinessLoans':
      case 'BusinessOverdraft':
      case 'Commercial':
      case 'CorporateLoans':
        return application.PricingDetails.LoanAmount;
      case 'Consumer':
        return application.PricingDetails.LoanAmount;
      case 'TradeFinance':
      case 'InsurancePremium':
        return null;
    }
  }
  return null;
}

export const applicationToFacilityEstablishmentFee = (application: Application): number | null => {
  if (application) {
    switch(application.ApplicationType) {
      case 'BusinessOverdraft':
      case 'CorporateLoans':
        return application.PricingDetails.FacilityEstablishmentFee
    }
  }
  return null;
}

export const applicationToAsset = (application: Application): AssetSelectionComponentValue => {
  if (application) {
    switch(application.ApplicationType) {
      case 'AssetFinance':
      case 'Consumer':
        const assetCategoryIndex = application.AppInfo.AssetCategory;
        const assetCategoryTypeIndex = application.AppInfo.AssetType;
        const assetCategory = getAssetCategory(assetCategoryIndex);
        const assetCategoryType = getAssetCategoryType(assetCategoryIndex, assetCategoryTypeIndex);
        // NOTE: assetCategory or assetCategoryType is falsy, a valid asset was not entered
        if (!assetCategoryIndex || !assetCategoryTypeIndex) {
          return null;
        }
        return {
          category: assetCategory ?? {index: assetCategoryIndex, value: ''},
          type: assetCategoryType ?? {index: assetCategoryTypeIndex, value: ''},
          model: application.AssetSpec?.vehicle,
          year: String(application.AssetSpec?.year),
          family: application.AssetSpec?.family,
          make: application.AssetSpec?.make,
          description: application.AssetSpec?.description,
          OtherCar: application.AssetSpec?.OtherCar,
          truckGrossVehicleWeight: application.AssetSpec?.truckGrossVehicleWeight,
          assetPurpose:application.AssetSpec?.assetPurpose,
          assetPurposeDescription:application.AssetSpec?.assetPurposeDescription,
          assetExistingFinanceCommitment:application.AssetSpec?.assetExistingFinanceCommitment,
          vehicletype:application.AssetSpec?.vehicletype,
          rbid:application.AssetSpec?.rbid,
          rbc:application.AssetSpec?.rbc,
          newprice:application.AssetSpec?.newprice,
          month:application.AssetSpec?.month,
          makecode:application.AssetSpec?.makecode,
          importflag:application.AssetSpec?.importflag,
          goodretail: application.AssetSpec?.goodretail,
          goodwhs: application.AssetSpec?.goodwhs,
          avgretail: application.AssetSpec?.avgretail,
          avgwhs: application.AssetSpec?.avgwhs,
          bodystyle: application.AssetSpec?.bodystyle,
          // vehicle: application.AssetSpec.vehicle,
        }
      case 'BusinessLoans': // not used
      case 'BusinessOverdraft':
        return null;
      case 'TradeFinance':
      case 'CorporateLoans':
      case 'InsurancePremium':
        return null;
    }
  }
  return null;
}

export const applicationToAssetCondition = (application: Application): AssetConditionValue => {
  if (application) {
    switch(application.ApplicationType) {
      case 'AssetFinance':
      case 'Consumer':
        return { type: _.toLower(application.PricingDetails.AssetCondition) === 'used' ? 'Used' : 'New', name: ''};
      case 'BusinessLoans':  // not used
      case 'BusinessOverdraft':
        return null;
      case 'TradeFinance':
      case 'CorporateLoans':
      case 'InsurancePremium':
        return null;
    }
  }
  return null;
}
export const applicationToApplicantType = (application: Application): CustomerApplicantTypeValue => {
  if (application) {
    switch(application.ApplicationType) {
      case 'AssetFinance':
      case 'BusinessLoans':
      case 'BusinessOverdraft':
      case 'InsurancePremium':
      case 'CorporateLoans':
      case 'Consumer':
          const f =  CustomerApplicantTypeOptions.find(opt => _.toLower(opt.type) ===  _.toLower(application.AppInfo?.MetApplicant));
          return f ? f : null;
        case 'TradeFinance':
    }
  }
  return null;
}

export const applicationToPrivateSales = (application: Application): PrivateSaleType | null => {
  if (application) {
    switch(application.ApplicationType) {
      case 'AssetFinance':
      case 'Consumer':
        return application.PricingDetails.PrivateSale;
      case 'BusinessLoans':  // not used
      case 'BusinessOverdraft':  // not used
      case 'TradeFinance':
      case 'CorporateLoans':
      case 'InsurancePremium':
        return null;
    }
  }
  return null;
}

export const applicationToTransactionValue = (application: Application): TransactionValue => {
  if (application) {
    switch(application.ApplicationType) {
      case 'AssetFinance':
      case 'Consumer':
        if (application?.PricingDetails?.TransactionType) {
          // return { type: application.PricingDetails.TransactionType, name: application.PricingDetails.TransactionType }
          const v = (TransactionValueOptions.find(opt => _.toLower(opt.type) == _.toLower(application.PricingDetails.TransactionType))) ?? null;
          return v;
        } else if (application?.PricingDetails?.PrivateSale) {
          if (_.toLower(application.PricingDetails.PrivateSale) === 'yes') {
            // return { type: 'Private Sale', name: 'Private Sale' }
            const v = (TransactionValueOptions.find(opt => opt.type == 'Private Sale')) ?? null;
            return v;
          } else {
            // return { type: 'Dealer Sale', name: 'Dealer Sale' }
            const v = (TransactionValueOptions.find(opt => opt.type == 'Dealer Sale')) ?? null;
            return v;
          }
        }
        return null;
      case 'BusinessLoans':  // not used
      case 'BusinessOverdraft':  // not used
      case 'TradeFinance':
      case 'CorporateLoans':
      case 'InsurancePremium':
        return null;
    }
  }
  return null;
}

export const applicationToLoanTerms = (application: Application): LoanTermValue | null => {
  if (application) {
    switch(application.ApplicationType) {
      case 'AssetFinance': {
        const f = LoanTermsOptions.find(opt => opt.type === String(application.PricingDetails.LoanTerm));
        if (!f) {
          return {type: String(application.PricingDetails.LoanTerm) as LoanTermType, name: `${application.PricingDetails.LoanTerm} months`}
        }
        return f ? f : null;
      }
      case 'CorporateLoans':
      case 'BusinessLoans':
      case 'BusinessOverdraft': {
        const f = BusinessLoanTermsOptions.find(opt => opt.type === String(application.PricingDetails.LoanTerm));
        if (!f) {
          return {type: String(application.PricingDetails.LoanTerm) as LoanTermType, name: `${application.PricingDetails.LoanTerm} months`}
        }
        return f ? f : null;
      }
      case 'Consumer': {
        const f = ConsumerLoanTermsOptions.find(opt => opt.type === String(application.PricingDetails.LoanTerm));
        if (!f) {
          return {type: String(application.PricingDetails.LoanTerm) as LoanTermType, name: `${application.PricingDetails.LoanTerm} months`}
        }
        return f ? f : null;
      }
      case 'TradeFinance':
      case 'InsurancePremium':
        return null;
    }
  }
  return null;
}

export const applicationToLoanTermsValue = (application: Application): number | null => {
  if (application) {
    switch(application.ApplicationType) {
      case 'Consumer':
      case 'AssetFinance':
      case 'BusinessLoans':
        const f = application.PricingDetails.LoanTerm;
        return f ? f : null;
    }
  }
  return null;
}

// for commercials
export const applicationToLoanTerms2 = (application: Application): LoanTermValue | null => {
  if (application) {
    switch(application.ApplicationType) {
      case 'Commercial':
      case 'AssetFinance': {
        const f = CommercialLoanTermsOptions.find(opt => opt.type === String(application.PricingDetails.LoanTerm));
        return f ? f : null;
      }
    }
  }
  return null;
}


export const applicationToPaymentFrequency = (application: Application): PaymentFrequencyValue => {
  if (application) {
    switch(application.ApplicationType) {
      case 'AssetFinance':
      case 'BusinessLoans':
      case 'BusinessOverdraft':
      case 'Commercial':
      case 'CorporateLoans':
      case 'Consumer': {
        const f = PaymentFrequencyOptions.find(opt => _.toLower(opt.type) === _.toLower(application.PricingDetails.PaymentPeriod ?? ''));
        return f ? f : null;
      }
      case 'TradeFinance':
      case 'InsurancePremium':
        return null;
    }
  }
  return null;
}

export const applicationToFinanceType = (application: Application): FinanceTypeValue => {
  if (application) {
    switch(application.ApplicationType) {
      case 'AssetFinance':
      case 'Consumer':
        const f =  FinanceTypeOptions.find(opt => _.toLower(opt.name) ===  _.toLower(application.AppInfo.FinanceType));
        if (f) {
          return f;
        }
        return null;
      case 'BusinessLoans': // not used
      case 'BusinessOverdraft':
        return null;
      case 'TradeFinance':
      case 'CorporateLoans':
      case 'InsurancePremium':
        return null;
    }
  }
  return null;
}

export const applicationToDocFeeFinanced = (application: Application): boolean => {
  if (application) {
    switch(application.ApplicationType) {
      case 'AssetFinance':
      case 'Consumer':
      case 'BusinessLoans':
        return _.toLower(application.PricingDetails.DocFeeFinanced) === 'yes';
      case 'BusinessOverdraft':
        return false;
      case 'TradeFinance':
      case 'CorporateLoans':
      case 'InsurancePremium':
        return false;
    }
  }
  return false;
}

export const applicationToBalloonPayment = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'BusinessLoans':
    case 'BusinessOverdraft':
    case 'Consumer':
      return application.PricingDetails.BalloonPayment;
  }
  return 0;
}

export const applicationToBalloonPaymentPrecentage = (application: Application): BalloonPaymentValue => {
  if (application) {
    switch(application.ApplicationType) {
      case 'AssetFinance':
      case 'Consumer':
        const op = BalloonPaymentOptions.find(opt => opt.type === String(application.PricingDetails.BalloonPaymentPercentage));
        return op ? op : null;
      case 'BusinessLoans':  // not used
      case 'BusinessOverdraft':
        return null;
      case 'TradeFinance':
      case 'CorporateLoans':
      case 'InsurancePremium':
        return null;
    }
  }
  return null;
}

export const applicationToBalloonPaymentPrecentageNumber = (application: Application): number | null => {
  if (application) {
    switch(application.ApplicationType) {
      case 'AssetFinance':
      case 'Consumer':
        return application.PricingDetails.BalloonPaymentPercentage;
      case 'BusinessLoans':  // not used
      case 'BusinessOverdraft':
      case 'TradeFinance':
      case 'CorporateLoans':
      case 'InsurancePremium':
        return null;
    }
  }
  return null;
}

export const applicationToBrokerOriginationFee = (application: Application): number | null => {
  if (application) {
    switch(application.ApplicationType) {
      case 'AssetFinance':
      case 'Consumer':
        return application.PricingDetails.BrokerOriginationFee;
      case 'BusinessLoans':  // not used
      case 'BusinessOverdraft':
        return null;
      case 'TradeFinance':
      case 'CorporateLoans':
      case 'InsurancePremium':
        return null;
    }
  }
  return null;
}

export const applicationToBrokerageAmount = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'BusinessLoans':
    case 'BusinessOverdraft':
    case 'CorporateLoans':
    case 'Consumer':
    case 'InsurancePremium':
      return application.PricingDetails.BrokerageAmount ? Number(application.PricingDetails.BrokerageAmount) : null;
  }
  return null;
}

export const applicationToBrokerage = (application: Application): BrokerageSelectionValue => {
  if (application) {
    switch(application.ApplicationType) {
      case 'AssetFinance':
      case 'BusinessLoans':
      case 'BusinessOverdraft':
      case 'Consumer': {
        const op = BrokerageOptions.find(opt => Number(opt.type) === Number(application.PricingDetails.Brokerage))
        return op ? op : null;
      }
      case 'TradeFinance':
      case 'CorporateLoans':
      case 'InsurancePremium':
        return null;
    }
  }
  return null;
}

export const applicationToFacilityEstablishmentFeePercent = (application: Application): FacilityEstablishmentValue => {
  if (application) {
    switch(application.ApplicationType) {
      case 'AssetFinance':
      case 'BusinessLoans':
      case 'BusinessOverdraft':
      case 'CorporateLoans':
      {
        const facilityEstablishmentFeePercent = application.PricingDetails?.FacilityEstablishmentFeePercent ?? 0;
        const value = facilityEstablishmentFeePercent.toFixed(1); // Generating type as a string with 1 decimal
        const name = `${parseFloat(value).toFixed(2)}%`; // Generating name as a percentage with 2 decimal points
        return { type: value as FacilityEstablishmentType, name };
      }
      case 'Consumer':
      case 'TradeFinance':
      case 'InsurancePremium':
        return null;
    }
  }
  return null;
}

export const applicationToPropertyOwner = (application: Application): boolean => {
  if (application) {
    switch(application.ApplicationType) {
      case 'AssetFinance':
      case 'BusinessLoans':
      case 'BusinessOverdraft':
      case 'Commercial':
      case 'CorporateLoans':
      case 'Consumer': {
        return _.toLower(application.PricingDetails.PropertyOwner) === 'yes';
      }
      case 'TradeFinance':
      case 'InsurancePremium':
        return false;
    }
  }
  return false;
}

export const applicationToAbnGstAgeAboveThreshold = (application: Application): boolean => {
  if (application) {
    switch(application.ApplicationType) {
      case 'AssetFinance':
      case 'BusinessLoans':
      case 'BusinessOverdraft':
      case 'TradeFinance':
      case 'CorporateLoans':
        return ((application.CompanyDetails?.GSTAgeMonths ?? application.CompanyDetails?.ABNAgeMonths ?? 0) > 24);
      case 'Consumer':
        return false;
      case 'InsurancePremium':
        return false;
    }
  }
  return false;
}

export const applicationToCrediRateAdjustment = (application: Application): number => {
  if (application) {
    switch(application.ApplicationType) {
      case 'AssetFinance':
      case 'BusinessLoans':
      case 'BusinessOverdraft':
      case 'Consumer':
      case 'CorporateLoans':
        return (application.PricingDetails.CreditRateAdjustment ?? 0.0);
      case 'TradeFinance':
      case 'InsurancePremium':
        return 0;
    }
  }
  return 0;
}

export const applicationToHybrid = (application: Application): boolean => {
  if (application) {
    switch(application.ApplicationType) {
      case 'Consumer': {
        return _.toLower(application.PricingDetails.Hybrid) === 'yes';
      }
      case 'AssetFinance':
      case 'BusinessLoans':
      case 'BusinessOverdraft':
      case 'Commercial':
      case 'TradeFinance':
      case 'CorporateLoans':
      case 'InsurancePremium':
        return false;
    }
  }
  return false;
}

export const applicationToRateDiscount = (application: Application): number => {
  if (application) {
    switch(application.ApplicationType) {
      case 'Consumer':
        return (application.PricingDetails.RateDiscount ?? 0);
      default:
        return 0;
    }
  }
  return 0;
}

export const applicationToMonthlyAccountKeepingFee = (application: Application): number => {
  if (application) {
    switch(application.ApplicationType) {
      case 'Consumer':
        return (application.PricingDetails.MonthlyAccountKeepingFee ?? 0);
      default:
        return 0;
    }
  }
  return 0;
}

export const applicationToRepaymentPlusMonthlyAccountKeepingFee = (application: Application): number => {
  if (application) {
    switch(application.ApplicationType) {
      case 'Consumer':
        const paymentFrequency = applicationToPaymentFrequency(application);
        const monthlyAccountKeepingFee = applicationToMonthlyAccountKeepingFee(application);
        const repayment = Number(application?.PricingDetails?.Repayment ?? 0);
        if (paymentFrequency?.type === 'Weekly') {
          return _.round(repayment + monthlyAccountKeepingFee / 4, 2);
        } else if (paymentFrequency?.type === 'Fortnightly') {
          return _.round(repayment + monthlyAccountKeepingFee / 2, 2);
        } else {
          return _.round(repayment + monthlyAccountKeepingFee, 2);
        }
      default:
        return 0;
    }
  }
  return 0;
}

export const applicationToRepaymentType = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'BusinessLoans':
    case 'Consumer':
      const f =  RepaymentTypeOptions.find(opt => _.toLower(opt.type) ===  _.toLower(application.PricingDetails.RepaymentType));
          return f ? f : null;
  }
  return null;
}

export const applicationToLastUpdatedByUser = (application: Application) => {
  if (application) {
    switch(application.ApplicationType) {
      case 'AssetFinance':
      case 'BusinessLoans':
      case 'BusinessOverdraft':
        return (application.PricingDetails.LastUpdatedByUser ?? null);
      case 'TradeFinance':
      case 'CorporateLoans':
      case 'Consumer':
      case 'InsurancePremium':
        return null;
    }
  }
  return null;
}

export const applicationToAdverseOnFile = (application: Application): boolean => {
  if (application) {
    switch(application.ApplicationType) {
      case 'AssetFinance':
      case 'BusinessLoans':
      case 'BusinessOverdraft':
      case 'Consumer':
      case 'CorporateLoans':
        return (_.toLower(application.PricingDetails.AdverseOnFile) === 'yes');
      case 'TradeFinance':
      case 'InsurancePremium':
        return false;
    }
  }
  return false;
}

export const applicationToPreviousLoan = (application: Application): boolean => {
  if (application) {
    switch(application.ApplicationType) {
      case 'AssetFinance':
      case 'BusinessLoans':
      case 'BusinessOverdraft':
      case 'Consumer':
      case 'CorporateLoans':
        return (_.toLower(application.PricingDetails.PreviousLoan) === 'yes');
      case 'TradeFinance':
      case 'InsurancePremium':
        return false;
    }
  }
  return false;
}

export const applicationToLtv = (application: Application): LtvSelectionType | null => {
  if (application) {
    switch (application.ApplicationType) {
      case 'CorporateLoans':
        return (application.PricingDetails.ltv ?? null);
      case 'AssetFinance':
      case 'BusinessLoans':
      case 'BusinessOverdraft':
      case 'Consumer':
      case 'TradeFinance':
      case 'InsurancePremium':
        return null;
    }
  }
  return null;
}


export const applicationToSecurityType = (application: Application): SecurityTypeSelectionType | null => {
  if (application) {
    switch (application.ApplicationType) {
      case 'CorporateLoans':
        return (application.PricingDetails.securityType ?? null);
      case 'AssetFinance':
      case 'BusinessLoans':
      case 'BusinessOverdraft':
      case 'Consumer':
      case 'TradeFinance':
      case 'InsurancePremium':
        return null;
    }
  }
  return null;
}

export const applicationToEquifaxScoreAboveThreshold = (application: Application): boolean => {
  if (application) {
    switch(application.ApplicationType) {
      case 'AssetFinance':
      case 'BusinessLoans':
      case 'BusinessOverdraft':
      case 'Consumer':
      case 'CorporateLoans':
        return _.toLower(application.PricingDetails.EquifaxScoreAbove600) === 'yes';
      case 'TradeFinance':
      case 'InsurancePremium':
        return false;
    }
  }
  return false;
}

export const applicationToDirectorScore = (application: Application): boolean => {
  if (application) {
    switch(application.ApplicationType) {
      case 'BusinessOverdraft':
      case 'BusinessLoans':
      case 'CorporateLoans':
        return _.toLower(application.PricingDetails.DirectorScoreRate) === 'yes';
      case 'TradeFinance':
      case 'InsurancePremium':
        return false;
    }
  }
  return false;
}

export const applicationToOrganisationType = (application: Application): EntityTypeValue => {
  if (application) {
    switch(application.ApplicationType) {
      case 'AssetFinance':
      case 'BusinessLoans':
      case 'BusinessOverdraft':
      case 'InsurancePremium':
      case 'TradeFinance':
      case 'CorporateLoans':
      case 'Commercial':
        const commercialEntity = primaryCommercialEntity(application);
        if (commercialEntity) {
          if (commercialEntity.EntityType === 'PTY') {
            const t = EntityTypeOptions.find(opt => opt.type === 'company');
            return t ? t : null;
          } else if (commercialEntity.EntityType === 'P/L') {
            const t = EntityTypeOptions.find(opt => opt.type === 'other');
            return t ? t : null;
          } else if (commercialEntity.EntityType === 'PTNR') {
            const t = EntityTypeOptions.find(opt => opt.type === 'partnership');
            return t ? t : null;
          } else if (commercialEntity.EntityType === 'TRST') {
            const t = EntityTypeOptions.find(opt => opt.type === 'trust');
            return t ? t : null;
          } else if (commercialEntity.EntityType === 'SLTR') {
            const t = EntityTypeOptions.find(opt => opt.type === 'sole-trader');
            return t ? t : null;
          }
        }
        return null;
      case 'Consumer':
        return null;
    }
  }
  return null;
}

export const applicationToAbn = (application: Application): string | null => {
  if (application) {
    switch(application.ApplicationType) {
      case 'AssetFinance':
      case 'BusinessLoans':
      case 'BusinessOverdraft':
      case 'InsurancePremium':
      case 'TradeFinance':
      case 'CorporateLoans':
      case 'Commercial':
        const commercialEntity = primaryCommercialEntity(application);
        if (commercialEntity && commercialEntity.ABN) {
          return commercialEntity.ABN;
        }
        return null;
      case 'Consumer':
        return null;
    }
  }
  return null;
}

export const applicationToAcn = (application: Application): string | null => {
  if (application) {
    switch(application.ApplicationType) {
      case 'AssetFinance':
      case 'BusinessLoans':
      case 'BusinessOverdraft':
      case 'InsurancePremium':
      case 'TradeFinance':
      case 'CorporateLoans':
      case 'Commercial':
        const commercialEntity = primaryCommercialEntity(application);
        if (commercialEntity && commercialEntity.ACN) {
          return commercialEntity.ACN;
        }
        return null;
      case 'Consumer':
        return null;
    }
  }
  return null;
}

export const applicationToRevenue = (application: Application): number | null => {
  if (application) {
    switch(application.ApplicationType) {
      case 'AssetFinance':
      case 'BusinessLoans':
      case 'BusinessOverdraft':
      case 'InsurancePremium':
      case 'TradeFinance':
      case 'CorporateLoans':
      case 'Commercial':
        const commercialEntity = primaryCommercialEntity(application);
        if (commercialEntity) {
          return commercialEntity.Revenue;
        }
        return null;
      case 'Consumer':
        return null;
    }
  }
  return null;
}

export const applicationToOperatesInCommercialPremise = (application: Application): boolean => {
  if (application) {
    switch(application.ApplicationType) {
      case 'AssetFinance':
      case 'BusinessLoans':
      case 'BusinessOverdraft':
      case 'InsurancePremium':
      case 'TradeFinance':
      case 'CorporateLoans':
      case 'Commercial':
        const commercialEntity = primaryCommercialEntity(application);
        if (commercialEntity) {
          return commercialEntity.OperateatCommercialAddressFlag;
        }
        return false;
      case 'Consumer':
        return false;
    }
  }
  return false;
}

export const applicationToPrimaryIndustry = (application: Application): PrimaryIndustrySelectionValue => {
  if (application) {
    switch(application.ApplicationType) {
      case 'AssetFinance':
      case 'BusinessLoans':
      case 'BusinessOverdraft':
      case 'InsurancePremium':
      case 'TradeFinance':
      case 'CorporateLoans':
      case 'Commercial':
        const commercialEntity = primaryCommercialEntity(application);
        if (commercialEntity && commercialEntity.PrimaryIndustry) {
          const i = commercialEntity.PrimaryIndustry;
          const o =  PrimaryIndustrySelectionOptions.find(opt => opt.division === i);
          return o ? o : null;
        }
        return null;
      case 'Consumer':
        return null;
    }
  }
  return null;
}

export const applicationToIndustrySector = (application: Application): SecondaryIndustrySelectionValue => {
  if (application) {
    switch(application.ApplicationType) {
      case 'AssetFinance':
      case 'BusinessLoans':
      case 'BusinessOverdraft':
      case 'InsurancePremium':
      case 'TradeFinance':
      case 'CorporateLoans':
      case 'Commercial':
        const commercialEntity = primaryCommercialEntity(application);
        if (commercialEntity && commercialEntity.IndustrySector) {
          const i = commercialEntity.IndustrySector;
          const o: SecondaryIndustrySelectionValue =  { code: i, name: ''};
          return o ? o : null;
        }
        return null;
      case 'Consumer':
        return null;
    }
  }
  return null;
}

export const applicationToPrimaryAddress = (application: Application): Address2ComponentValue => {
  if (application) {
    switch(application.ApplicationType) {
      case 'AssetFinance':
      case 'BusinessLoans':
      case 'BusinessOverdraft':
      case 'InsurancePremium':
      case 'TradeFinance':
      case 'CorporateLoans':
      case 'Commercial':
        const commercialEntity = primaryCommercialEntity(application);
        if (commercialEntity && commercialEntity.PrinciplePlaceofBusiness) {
          const parsedAddress = parseRawAddress(commercialEntity.PrinciplePlaceofBusiness.UnformattedAddress);
          return {
            address: commercialEntity.PrinciplePlaceofBusiness.UnformattedAddress,
            UnitNumber: parsedAddress?.unit_number ?? '',
            StreetNumber: commercialEntity.PrinciplePlaceofBusiness.StreetNumber ?? parsedAddress?.street_number ?? '',
            StreetName: commercialEntity.PrinciplePlaceofBusiness.StreetName ?? parsedAddress?.street_name ?? '',
            StreetType: commercialEntity.PrinciplePlaceofBusiness.StreetType ?? parsedAddress?.street_type ?? '',
            Suburb: commercialEntity.PrinciplePlaceofBusiness.Suburb ?? parsedAddress?.locality ?? '',
            State: commercialEntity.PrinciplePlaceofBusiness.State ?? parsedAddress?.region ?? '',
            Postcode: commercialEntity.PrinciplePlaceofBusiness.Postcode ?? parsedAddress?.postal_code ?? '',
          }
        }
        return null;
      case 'Consumer':
        return null;
    }
  }
  return null;
}

export const applicationToBusinessLandline = (application: Application): string | null => {
  if (application) {
    switch(application.ApplicationType) {
      case 'AssetFinance':
      case 'BusinessLoans':
      case 'BusinessOverdraft':
      case 'InsurancePremium':
      case 'Commercial':
      case 'TradeFinance':
      case 'CorporateLoans':
      case 'Consumer':
        return '';
    }
  }
  return '';
}

// trade finance
export const applicationToDirectors = (application: Application): DirectorValue => {
  if (application) {
    switch(application.ApplicationType) {
      case 'TradeFinance':
      case 'CorporateLoans':
        const directors: NonNullable<DirectorValue> = [];
        const individuals = application.Individuals ?? [];
        for (const individual of individuals) {
          if (individual.Role !== 'Guarantor') { // Director
            const title = TitleOptions.find(opt => _.toLower(opt.type) === _.toLower(individual.Title));
            // const propertyAddress: AddressComponentValue = individual.AddressofPropertyOwned ? { address: individual.AddressofPropertyOwned.UnformattedAddress, place: undefined, location: undefined}: null;
            const address: Address2ComponentValue = individual.HomeAddress ? {
              address: individual.HomeAddress.UnformattedAddress,
              StreetNumber: individual.HomeAddress.StreetNumber,
              StreetName: individual.HomeAddress.StreetName,
              StreetType: individual.HomeAddress.StreetType,
              Suburb: individual.HomeAddress.Suburb,
              State: individual.HomeAddress.State,
              UnitNumber: individual.HomeAddress.UnitNumber,
              Postcode: individual.HomeAddress.Postcode,
            } : null;

            const assets: PersonalAsset[] = assetsFromIndividual(individual);
            const liabilities: PersonalLiability[] = liabilitiesFromIndividual(individual);

            const director: Exclude<DirectorValue, null>[number] = {
              title: title ? title : null,
              email: individual.Email,
              dob: moment(individual.DoB, 'YYYY-MM-DD'),
              firstName: individual.GivenName,
              middleName: individual.MiddleName,
              lastName: individual.SurName,
              gender: _.toLower(individual.Gender) === 'm' ? 'Male' : 'Female',
              mobileNumber: individual.MobileNumber,
              // propertyOwner: !!individual.PropertyOwnerFlag,
              propertyOwner: individualToPropertyOwnerWithAddress(individual),
              residentialAddress: address,
              kind: 'Director',
              type: (!!individual.PropertyOwnerFlag ? 'WithPropertyAddress' : 'WithoutPropertyAddress') as any,
              guarantor: !!individual.GuarantorFlag,
              privacyConsentObtained: !!individual.PrivacyConsent,
              // propertyAddress: propertyAddress,
              // propertyValue: individual.PropertyValue ?? 0,
              // mortgageBalance: individual.MortgageBalance ?? 0,
              personalAssets: {total: 0, assets},
              personalLiabilities: {total: 0, liabilities},
            }
            directors.push(director);
          }
        }
        return directors.length ? directors : null;
    }
  }
  return null;
}

const individualToPropertyOwnerWithAddress = (individual: IndividualWithAssetsAndLiabilities): PropertyOwnerWithAddressValue | boolean => {
  if(individual.PropertyOwnerFlag) {
    const o = {
      propertyOwner: true, propertyValue: individual.PropertyValue, mortgageValue: individual.MortgageBalance,
      address: {
        address: individual.AddressofPropertyOwned?.UnformattedAddress ?? '',
        StreetNumber: individual.AddressofPropertyOwned?.StreetNumber ?? '',
        StreetName: individual.AddressofPropertyOwned?.StreetName ?? '',
        StreetType: individual.AddressofPropertyOwned?.StreetType ?? '',
        Suburb: individual.AddressofPropertyOwned?.Suburb ?? '',
        State: individual.AddressofPropertyOwned?.State ?? '',
        UnitNumber: individual.AddressofPropertyOwned?.UnitNumber ?? '',
        Postcode: individual.AddressofPropertyOwned?.Postcode ?? '',
      }
    }
    return o;
  } else {
    return false;
  }
}


export const applicationToPrivacyConfirmation = (application: Application): boolean => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'BusinessLoans':
    case 'BusinessOverdraft':
    case 'InsurancePremium':
    case 'Commercial':
    case 'TradeFinance':
    case 'CorporateLoans':
    case 'Consumer': {
      return yesNoToBoolean(application.AppInfo?.PrivacyConfirmation ?? 'No');
    }
  }
}

export const applicationToApplicants = (application: Application): DirectorValue | SoleTraderValue | MemberValue | PartnerValue | TrusteeValue => {
  if (application) {
    switch(application.ApplicationType) {
      case 'AssetFinance':
      case 'BusinessLoans':
      case 'BusinessOverdraft':
      case 'InsurancePremium':
      case 'Commercial':
      case 'TradeFinance':
      case 'CorporateLoans':
        const commercialEntity = primaryCommercialEntity(application);
        if (commercialEntity && commercialEntity.EntityType) {
          if (commercialEntity.EntityType === 'PTY') {
            const t = EntityTypeOptions.find(opt => opt.type === 'company');
            if (t) {
              const directors: NonNullable<DirectorValue> = [];
              const individuals = application.Individuals ?? [];
              for (const individual of individuals) {
                if (individual.Role !== 'Guarantor') { // Director
                  const title = TitleOptions.find(opt => _.toLower(opt.type) === _.toLower(individual.Title));
                  // const propertyAddress: AddressComponentValue = individual.AddressofPropertyOwned ? { address: individual.AddressofPropertyOwned.UnformattedAddress, place: undefined, location: undefined}: null;
                  const address: Address2ComponentValue = individual.HomeAddress ? {
                    address: individual.HomeAddress.UnformattedAddress,
                    StreetNumber: individual.HomeAddress.StreetNumber,
                    StreetName: individual.HomeAddress.StreetName,
                    StreetType: individual.HomeAddress.StreetType,
                    Suburb: individual.HomeAddress.Suburb,
                    State: individual.HomeAddress.State,
                    UnitNumber: individual.HomeAddress.UnitNumber,
                    Postcode: individual.HomeAddress.Postcode,
                  } : null;

                  const director: Exclude<DirectorValue, null>[number] = {
                    title: title ? title : null,
                    email: individual.Email,
                    dob: moment(individual.DoB, 'YYYY-MM-DD'),
                    firstName: individual.GivenName,
                    middleName: individual.MiddleName,
                    lastName: individual.SurName,
                    gender: _.toLower(individual.Gender) === 'm' ? 'Male' : 'Female',
                    mobileNumber: individual.MobileNumber,
                    propertyOwner: individualToPropertyOwnerWithAddress(individual),
                    residentialAddress: address,
                    kind: 'Director',
                    type: (!!individual.AddressofPropertyOwned ? 'WithPropertyAddress' : 'WithoutPropertyAddress') as any,
                    guarantor: !!individual.GuarantorFlag,
                    privacyConsentObtained: !!individual.PrivacyConsent,
                    // propertyAddress: propertyAddress,
                    // propertyValue: individual.PropertyValue ?? 0,
                    // mortgageBalance: individual.MortgageBalance ?? 0,
                    personalAssets: null,
                    personalLiabilities: null,
                  }
                  console.log('*^^^^^^^^^^^^^applicationToApplicants^^^^^^^^^^^^^^^^^^^', individual, director);
                  directors.push(director);
                }
              }
              return directors.length ? directors : null;
            }
          } else if (commercialEntity.EntityType === 'PTNR') {
            const t = EntityTypeOptions.find(opt => opt.type === 'partnership');
            if (t) {
              const partners: NonNullable<PartnerValue> = [];

              const individuals = application.Individuals ?? [];
              // individuals
              for (const individual of individuals) {
                if (individual.Role !== 'Guarantor') {  // Partner
                  const title = TitleOptions.find(opt => _.toLower(opt.type) === _.toLower(individual.Title));
                  const address: Address2ComponentValue = individual.HomeAddress ? {
                    address: individual.HomeAddress.UnformattedAddress,
                    StreetNumber: individual.HomeAddress.StreetNumber,
                    StreetName: individual.HomeAddress.StreetName,
                    StreetType: individual.HomeAddress.StreetType,
                    Suburb: individual.HomeAddress.Suburb,
                    State: individual.HomeAddress.State,
                    UnitNumber: individual.HomeAddress.UnitNumber,
                    Postcode: individual.HomeAddress.Postcode,
                  } : null;
                  const partner: NonNullable<PartnerValue>[number] = {
                    kind: 'Partner',
                    type: 'Individual',
                    title: title ? title : null,
                    email: individual.Email,
                    dob: moment(individual.DoB, 'YYYY-MM-DD'),
                    firstName: individual.GivenName,
                    lastName: individual.SurName,
                    middleName: individual.MiddleName,
                    mobileNumber: individual.MobileNumber,
                    propertyOwner: individual.PropertyOwnerFlag ?
                      { propertyOwner: true, propertyValue: individual.PropertyValue, mortgageValue: individual.MortgageBalance,
                        address: {
                          address: individual.AddressofPropertyOwned?.UnformattedAddress ?? '',
                          StreetNumber: individual.AddressofPropertyOwned?.StreetNumber ?? '',
                          StreetName: individual.AddressofPropertyOwned?.StreetName ?? '',
                          StreetType: individual.AddressofPropertyOwned?.StreetType ?? '',
                          Suburb: individual.AddressofPropertyOwned?.Suburb ?? '',
                          State: individual.AddressofPropertyOwned?.State ?? '',
                          UnitNumber: individual.AddressofPropertyOwned?.UnitNumber ?? '',
                          Postcode: individual.AddressofPropertyOwned?.Postcode ?? '',
                        }
                      } :
                      { propertyOwner: false },
                    residentialAddress: address,
                    guarantor: !!individual.GuarantorFlag,
                    privacyConsentObtained: !!individual.PrivacyConsent,
                    gender: _.toLower(individual.Gender) === 'm' ? 'Male' : 'Female',
                    personalLiabilities: null,
                    personalAssets: null,
                  }
                  partners.push(partner);
                }
              }

              // entities
              const entities = application.CommercialEntities;
              for (const entity of entities) {
                if (entity.Type === 'Partner') {
                  const partner: NonNullable<PartnerValue>[number] = {
                    type: 'Entity',
                    kind: 'Partner',
                    acn: entity.ACN,
                    abn: entity.ABN,
                    organisationType: toEntityTypeValue(entity.EntityType)!,
                    manualEntry: entity.manualEntry,
                    organisation: { name: entity.TradingName, displayName: entity.TradingName, acn: entity.ACN,  abn: entity.ABN, },
                  };
                  partners.push(partner);
                }
              }

              return partners.length ? partners : null;
            }
          } else if (commercialEntity.EntityType === 'TRST') {
            const t = EntityTypeOptions.find(opt => opt.type === 'trust');
            if (t) {
              const trustees: NonNullable<TrusteeValue> = [];
              // individuals
              const individuals = application.Individuals ?? [];
              for (const individual of individuals) {
                if (individual.Role !== 'Guarantor') {  // Trustee
                  const title = TitleOptions.find(opt => _.toLower(opt.type) === _.toLower(individual.Title));
                  const address: Address2ComponentValue = individual.HomeAddress ? {
                    address: individual.HomeAddress.UnformattedAddress,
                    StreetNumber: individual.HomeAddress.StreetNumber,
                    StreetName: individual.HomeAddress.StreetName,
                    StreetType: individual.HomeAddress.StreetType,
                    Suburb: individual.HomeAddress.Suburb,
                    State: individual.HomeAddress.State,
                    UnitNumber: individual.HomeAddress.UnitNumber,
                    Postcode: individual.HomeAddress.Postcode,
                  } : null;
                  const trustee: NonNullable<TrusteeValue>[number] = {
                    type: 'Individual',
                    kind: 'Trustee',
                    title: title ? title : null,
                    email: individual.Email,
                    dob: moment(individual.DoB, 'YYYY-MM-DD'),
                    firstName: individual.GivenName,
                    lastName: individual.SurName,
                    middleName: individual.MiddleName,
                    mobileNumber: individual.MobileNumber,
                    propertyOwner: individual.PropertyOwnerFlag ?
                      { propertyOwner: true, propertyValue: individual.PropertyValue, mortgageValue: individual.MortgageBalance,
                        address: {
                          address: individual.AddressofPropertyOwned?.UnformattedAddress ?? '',
                          StreetNumber: individual.AddressofPropertyOwned?.StreetNumber ?? '',
                          StreetName: individual.AddressofPropertyOwned?.StreetName ?? '',
                          StreetType: individual.AddressofPropertyOwned?.StreetType ?? '',
                          Suburb: individual.AddressofPropertyOwned?.Suburb ?? '',
                          State: individual.AddressofPropertyOwned?.State ?? '',
                          UnitNumber: individual.AddressofPropertyOwned?.UnitNumber ?? '',
                          Postcode: individual.AddressofPropertyOwned?.Postcode ?? '',
                        }
                      } :
                      { propertyOwner: false },
                    residentialAddress: address,
                    guarantor: !!individual.GuarantorFlag,
                    privacyConsentObtained: !!individual.PrivacyConsent,
                    gender: _.toLower(individual.Gender) === 'm' ? 'Male' : 'Female',
                    personalLiabilities: null,
                    personalAssets: null,
                  }
                  trustees.push(trustee);
                }
              }


              // entities
              const entities = application.CommercialEntities;
              for (const entity of entities) {
                if (entity.Type === 'Trustee') {
                  const trustee: NonNullable<TrusteeValue>[number] = {
                    type: 'Entity',
                    kind: 'Trustee',
                    acn: entity.ACN,
                    abn: entity.ABN,
                    manualEntry: entity.manualEntry,
                    organisationType: toEntityTypeValue(entity.EntityType)!,
                    organisation: { name: entity.TradingName, displayName: entity.TradingName, acn: entity.ACN,  abn: entity.ABN, },
                  };
                  trustees.push(trustee);
                }
              }

              return trustees.length ? trustees : null;
            }
          } else if (commercialEntity.EntityType === 'SLTR') {
            const t = EntityTypeOptions.find(opt => opt.type === 'sole-trader');
            if (t) {

              const individuals = application.Individuals ?? [];
              for (const individual of individuals) {
                if (individual.Role != 'Guarantor') {  // SoleTrader
                  const title = TitleOptions.find(opt => _.toLower(opt.type) === _.toLower(individual.Title));
                  const address: Address2ComponentValue = individual.HomeAddress ? {
                    address: individual.HomeAddress.UnformattedAddress,
                    StreetNumber: individual.HomeAddress.StreetNumber,
                    StreetName: individual.HomeAddress.StreetName,
                    StreetType: individual.HomeAddress.StreetType,
                    Suburb: individual.HomeAddress.Suburb,
                    State: individual.HomeAddress.State,
                    UnitNumber: individual.HomeAddress.UnitNumber,
                    Postcode: individual.HomeAddress.Postcode,
                  } : null;
                  const soleTrader: SoleTraderValue = {
                    kind: 'SoleTrader',
                    title: title ? title : null,
                    residentialAddress: address,
                    dob: moment(individual.DoB, 'YYYY-MM-DD'),
                    firstName: individual.GivenName,
                    lastName: individual.SurName,
                    propertyOwner: individual.PropertyOwnerFlag ?
                      { propertyOwner: true, propertyValue: individual.PropertyValue, mortgageValue: individual.MortgageBalance,
                        address: {
                          address: individual.AddressofPropertyOwned?.UnformattedAddress ?? '',
                          StreetNumber: individual.AddressofPropertyOwned?.StreetNumber ?? '',
                          StreetName: individual.AddressofPropertyOwned?.StreetName ?? '',
                          StreetType: individual.AddressofPropertyOwned?.StreetType ?? '',
                          Suburb: individual.AddressofPropertyOwned?.Suburb ?? '',
                          State: individual.AddressofPropertyOwned?.State ?? '',
                          UnitNumber: individual.AddressofPropertyOwned?.UnitNumber ?? '',
                          Postcode: individual.AddressofPropertyOwned?.Postcode ?? '',
                        }
                      } :
                      { propertyOwner: false },
                    guarantor: !!individual.GuarantorFlag,
                    privacyConsentObtained: !!individual.PrivacyConsent,
                    gender: _.toLower(individual.Gender) === 'm' ? 'Male' : 'Female',
                    email: individual.Email,
                    mobile: individual.MobileNumber,
                  };
                  return soleTrader;
                }
              }
            }
          }
        }
        return null;
      case 'Consumer':  // not applicable
        return null;
    }
  }
  return null;
}


export const applicationToGuarantors = (application: Application): GuarantorValue => {
  if (application) {
    switch(application.ApplicationType) {
      case 'AssetFinance':
      case 'Commercial':
      case 'BusinessLoans':
      case 'BusinessOverdraft':
      case 'CorporateLoans': {
        const guarantors: NonNullable<GuarantorValue> = [];
        for (const individual of (application.Individuals ?? [])) {
          if (individual.Role === 'Guarantor') {
            const title = TitleOptions.find(opt => _.toLower(opt.type) === _.toLower(individual.Title));
            const address: Address2ComponentValue = individual.HomeAddress ? {
              address: individual.HomeAddress.UnformattedAddress,
              StreetNumber: individual.HomeAddress.StreetNumber,
              StreetName: individual.HomeAddress.StreetName,
              StreetType: individual.HomeAddress.StreetType,
              Suburb: individual.HomeAddress.Suburb,
              State: individual.HomeAddress.State,
              UnitNumber: individual.HomeAddress.UnitNumber,
              Postcode: individual.HomeAddress.Postcode,
            } : null;
            const g: IndividualGuarantor = {
              type: 'Individual',
              kind: 'WithPropertyAddress',
              title: title ? title : null,
              email: individual.Email,
              dob: moment(individual.DoB, 'YYYY-MM-DD'),
              firstName: individual.GivenName,
              lastName: individual.SurName,
              middleName: individual.MiddleName,
              mobileNumber: individual.MobileNumber,
              gender: _.toLower(individual.Gender) === 'm' ? 'Male' : 'Female',
              propertyOwner: individual.PropertyOwnerFlag ?
                { propertyOwner: true, propertyValue: (individual.PropertyValue), mortgageValue: (individual.MortgageBalance),
                  address: {
                    address: individual.AddressofPropertyOwned?.UnformattedAddress ?? '',
                    StreetNumber: individual.AddressofPropertyOwned?.StreetNumber ?? '',
                    StreetName: individual.AddressofPropertyOwned?.StreetName ?? '',
                    StreetType: individual.AddressofPropertyOwned?.StreetType ?? '',
                    Suburb: individual.AddressofPropertyOwned?.Suburb ?? '',
                    State: individual.AddressofPropertyOwned?.State ?? '',
                    UnitNumber: individual.AddressofPropertyOwned?.UnitNumber ?? '',
                    Postcode: individual.AddressofPropertyOwned?.Postcode ?? '',
                  }
                } :
                { propertyOwner: false },
              residentialAddress: address,
              privacyConsentObtained: individual.PrivacyConsent,
              personalAssets: null,
              personalLiabilities: null,
            }
            guarantors.push(g);
          }
        }
        for (const commercial of application.CommercialEntities) {
          if (commercial.Type === 'Guarantor') {
            const g: EntityGuarantor = {
              type: 'Entity',
              organisationType: toEntityTypeValue(commercial.EntityType)!,
              abn: commercial.ABN,
              acn: commercial.ACN,
              organisation: {acn: commercial.ACN, abn: commercial.ABN, name: commercial.LegalName, displayName: commercial.LegalName },
              // organisation: (commercial.ABN ?
              //   { type: 'search-result', organisationName: commercial.LegalName, acn: commercial.ACN, abn: commercial.ABN, status: '', postcode: '', stateCode: '', entityTypeValue: toEntityTypeValue(commercial.EntityType)} :
              //   { type: 'free-text', organisationName: commercial.LegalName}),
            }
            guarantors.push(g);
          }
        }
        return guarantors.length ? guarantors : null;
      }
      // case 'BusinessOverdraft': {
      //   const guarantors: NonNullable<GuarantorValue> = [];
      //   for (const individual of application.Individuals) {
      //     if (individual.Role === 'Guarantor') {
      //       const title = TitleOptions.find(opt => _.toLower(opt.type) === _.toLower(individual.Title));
      //       const address: Address2ComponentValue = individual.HomeAddress ? {
      //         address: individual.HomeAddress.UnformattedAddress,
      //         StreetNumber: individual.HomeAddress.StreetNumber,
      //         StreetName: individual.HomeAddress.StreetName,
      //         StreetType: individual.HomeAddress.StreetType,
      //         Suburb: individual.HomeAddress.Suburb,
      //         State: individual.HomeAddress.State,
      //         UnitNumber: individual.HomeAddress.UnitNumber,
      //         Postcode: individual.HomeAddress.Postcode,
      //       } : null;
      //       const g: IndividualGuarantor = {
      //         type: 'Individual',
      //         kind: 'WithPropertyAddress',
      //         title: title ? title : null,
      //         email: individual.Email,
      //         dob: moment(individual.DoB, 'YYYY-MM-DD'),
      //         firstName: individual.GivenName,
      //         lastName: individual.SurName,
      //         middleName: individual.MiddleName,
      //         mobileNumber: individual.MobileNumber,
      //         gender: _.toLower(individual.Gender) === 'm' ? 'Male' : 'Female',
      //         propertyOwner: individual.PropertyOwnerFlag ?
      //           { propertyOwner: true, propertyValue: (individual.PropertyValue), mortgageValue: (individual.MortgageBalance),
      //             address: {
      //               address: individual.AddressofPropertyOwned?.UnformattedAddress ?? '',
      //               StreetNumber: individual.AddressofPropertyOwned?.StreetNumber ?? '',
      //               StreetName: individual.AddressofPropertyOwned?.StreetName ?? '',
      //               StreetType: individual.AddressofPropertyOwned?.StreetType ?? '',
      //               Suburb: individual.AddressofPropertyOwned?.Suburb ?? '',
      //               State: individual.AddressofPropertyOwned?.State ?? '',
      //               UnitNumber: individual.AddressofPropertyOwned?.UnitNumber ?? '',
      //               Postcode: individual.AddressofPropertyOwned?.Postcode ?? '',
      //             }
      //           } :
      //           { propertyOwner: false },
      //         residentialAddress: address,
      //         privacyConsentObtained: individual.PrivacyConsent,
      //         personalAssets: null,
      //         personalLiabilities: null,
      //       }
      //       guarantors.push(g);
      //     }
      //   }
      //   for (const commercial of application.CommercialEntities) {
      //     if (commercial.Type === 'Guarantor') {
      //       const g: EntityGuarantor = {
      //         type: 'Entity',
      //         organisationType: toEntityTypeValue(commercial.EntityType)!,
      //         abn: commercial.ABN,
      //         acn: commercial.ACN,
      //         organisation: { organisationName: commercial.LegalName, acn: commercial.ACN, abn: commercial.ABN, status: '', postcode: '', stateCode: '', entityTypeValue: toEntityTypeValue(commercial.EntityType)},
      //       }
      //       guarantors.push(g);
      //     }
      //   }
      //   return guarantors.length ? guarantors : null;
      // }
      case 'InsurancePremium': {
        return null;
      }
      case 'TradeFinance':
      case 'Consumer': {
        return null;
      }
    }
  }
  return null;
}

export const applicationToPrimaryContact = (application: Application): ContactValue | null => {
  if (application) {
    switch(application.ApplicationType) {
      case 'AssetFinance':
      case 'BusinessLoans':
      case 'BusinessOverdraft':
      case 'InsurancePremium':
      case 'Commercial':
      case 'TradeFinance':
      case 'CorporateLoans':
      case 'Consumer': {
        if(application.Contacts && application.Contacts.GivenName && application.Contacts.SurName && application.Contacts.Email){
          return {
            isManual: application.Contacts.isManual,
            title:  TitleSelectionValueOptions.find(option => option.type === application.Contacts.Title) || null,
            firstName: application.Contacts.GivenName,
            lastName: application.Contacts.SurName,
            mobileNumber: application.Contacts.Mobile,
            telephone: application.Contacts.Telephone,
            email: application.Contacts.Email,
            areaCode: application.Contacts.AreaCode,
          }
        }else {
          return null
        }
      }
    }
  }
  return null;
}

export const applicationToBrokerFlowDocumentId = (application: Application): string | null => {
  if (application) {
    switch(application.ApplicationType) {
      case 'AssetFinance':
      case 'BusinessLoans':
      case 'BusinessOverdraft':
      case 'CorporateLoans': {
        return application.AppInfo.BrokerflowDocumentID ?? null;
      }
      case 'Consumer': {
        return null;
      }
      case 'InsurancePremium': {
        return null;
      }
      case 'TradeFinance':
    }
  }
  return null;
}

export const applicationToReferences = (application: Application): ReferenceValue => {
  if (application) {
    switch(application.ApplicationType) {
      case 'AssetFinance':
      case 'BusinessLoans':
      case 'BusinessOverdraft':
      case 'Consumer':
      case 'Commercial':
      case 'TradeFinance':
      case 'CorporateLoans':
      case 'InsurancePremium': {
        return null;
      }
    }
  }
  return null;
}


export const applicationToLoanPurpose = (application: Application): LoanPurposeValue => {
  if (application) {
    switch(application.ApplicationType) {
      case 'AssetFinance':  // not used
        return null;
      case 'BusinessLoans':
      case 'BusinessOverdraft': {
        const lp = application.AppInfo.LoanPurpose;
        const p = LoanPurposeOptions.find(opt => _.toLower(opt.name).trim() === _.toLower(lp).trim());
        return p ? p : null;
      }
      case 'Consumer': {
        return null;
      }
      case 'InsurancePremium': {
        return null;
      }
      case 'TradeFinance':
      case 'CorporateLoans':
    }
  }
  return null;
}

export const applicationToAssetCategoryType = (application: Application): AssetCategoryTypeSelectionValue => {
  switch(application.ApplicationType) {
    case 'AssetFinance':  // not used (commercial app is asset finance)
    case 'Commercial':
      const index = application.AppInfo.AssetType;
      return {index, value: ''};
    case 'BusinessLoans':
    case 'BusinessOverdraft': {
      return null;
    }
    case 'Consumer': {
      return null;
    }
    case 'InsurancePremium': {
      return null;
    }
    case 'TradeFinance':
    case 'CorporateLoans':
  }
  return null;
}

export const applicationToAssetDescription = (application: Application): string | null => {

  switch(application.ApplicationType) {
    case 'AssetFinance':  // not used (commercial app is asset finance)
    case 'Commercial':
      return application.AssetSpec?.description ?? null;
    case 'BusinessLoans':
    case 'BusinessOverdraft': {
      return null;
    }
    case 'Consumer': {
      return null;
    }
    case 'InsurancePremium': {
      return null;
    }
    case 'TradeFinance':
    case 'CorporateLoans':
  }
  return null;
}

export const applicationToApplicantTitle = (application: Application): TitleSelectionValue | null => {
  if (application) {
    switch (application.ApplicationType) {
      case 'Consumer': {
        const applicant = (application.Individuals ?? []).find(i => i.Role === 'Applicant');
        if (applicant) {
          const t = TitleOptions.find(opt => _.toLower(opt.type) === _.toLower(applicant.Title));
          return t ? t : null;
        }
      }
    }
  }
  return null;
}

export const applicationToApplicantFirstName = (application: Application): string | null => {
  if (application) {
    switch(application.ApplicationType) {
      case 'Consumer': {  // only applicable to consumer finance
        const applicant = (application.Individuals ?? []).find(i => i.Role === 'Applicant');
        if (applicant) {
          return applicant.GivenName;
        }
      }
    }
  }
  return null;
}

export const applicationToApplicantLastName = (application: Application): string | null => {
  if (application) {
    switch(application.ApplicationType) {
      case 'Consumer': {  // only applicable to consumer finance
        const applicant = (application.Individuals ?? []).find(i => i.Role === 'Applicant');
        if (applicant) {
          return applicant.SurName;
        }
      }
    }
  }
  return null;
}

export const applicationToApplicantIdentification = (application: Application): IdentificationComponentValue | null => {
  if (application) {
    switch(application.ApplicationType) {
      case 'Consumer': {  // only applicable to consumer finance
        const applicant = (application.Individuals ?? []).find(i => i.Role === 'Applicant');
        if (applicant) {
          return applicant.Identification ?? null;
        }
      }
    }
  }
  return null;
}


export const applicationToApplicantMiddleName = (application: Application): string | null => {
  if (application) {
    switch(application.ApplicationType) {
      case 'Consumer': {  // only applicable to consumer finance
        const applicant = (application.Individuals ?? []).find(i => i.Role === 'Applicant');
        if (applicant) {
          return applicant.MiddleName;
        }
      }
    }
  }
  return null;
}

export const applicationToApplicantDob = (application: Application): Moment | null => {
  if (application) {
    switch(application.ApplicationType) {
      case 'Consumer': { // only applicable to consumer finance
        const applicant = (application.Individuals ?? []).find(i => i.Role === 'Applicant');
        if (applicant) {
          return  applicant.DoB ? moment(applicant.DoB, 'YYYY-MM-DD') : null;
        }
      }
    }
  }
  return null;
}

export const applicationToApplicantGender = (application: Application): GenderValue => {
  if (application) {
    switch(application.ApplicationType) {
      case 'Consumer': { // only applicable to consumer finance
        const applicant = (application.Individuals ?? []).find(i => i.Role === 'Applicant');
        if (applicant) {
          return _.toLower(applicant.Gender) === 'm' ? 'Male' : 'Female';
        }
      }
    }
  }
  return null;
}
export const applicationToApplicantResidentialAddress = (application: Application): Address2ComponentValue => {
  if (application) {
    switch(application.ApplicationType) {
      case 'Consumer': { // only applicable to consumer finance
        const applicant = (application.Individuals ?? []).find(i => i.Role === 'Applicant');
        if (applicant && applicant.HomeAddress) {
          const parsedAddress = parseRawAddress(applicant.HomeAddress.UnformattedAddress);
          const address: Address2ComponentValue = applicant.HomeAddress ? {
            address: applicant.HomeAddress.UnformattedAddress,
            UnitNumber: parsedAddress?.unit_number ?? '',
            StreetNumber: applicant.HomeAddress.StreetNumber ?? parsedAddress?.street_number ?? '',
            StreetName: applicant.HomeAddress.StreetNumber ?? parsedAddress?.street_name ?? '',
            StreetType: applicant.HomeAddress.StreetType ?? parsedAddress?.street_type ?? '',
            Suburb: applicant.HomeAddress.Suburb ?? parsedAddress?.locality ?? '',
            State: applicant.HomeAddress.State ?? parsedAddress?.region ?? '',
            Postcode: applicant.HomeAddress.Postcode ?? parsedAddress?.postal_code ?? '',
          } : null;
          return address;
        }
      }
    }
  }
  return null;
};
export const applicationToApplicantTimeInAddress = (application: Application): TimeInAddressValue => {
  if (application) {
    switch(application.ApplicationType) {
      case 'Consumer': { // only applicable to consumer finance
        const applicant = (application.Individuals ?? []).find(i => i.Role === 'Applicant');
        if (applicant) {
          const m = applicant.TimeatAddress ?? 0;
          const t: NonNullable<TimeInAddressValue> = {
            years: parseInt(String(m / 12)),
            months: (m % 12),
            previousAddress: {
              address: applicant.PreviousAddress?.UnformattedAddress ?? '',
              StreetNumber: applicant.PreviousAddress?.StreetNumber ?? '',
              StreetName: applicant.PreviousAddress?.StreetName ?? '',
              StreetType: applicant.PreviousAddress?.StreetType ?? '',
              Suburb: applicant.PreviousAddress?.Suburb ?? '',
              State: applicant.PreviousAddress?.State ?? '',
              UnitNumber: applicant.PreviousAddress?.UnitNumber ?? '',
              Postcode: applicant.PreviousAddress?.Postcode ?? '',
            },
          }
          return t;
        }
      }
    }
  }
  return null;
};
export const applicationToApplicantPrivacyConsent = (application: Application): boolean => {
  if (application) {
    switch(application.ApplicationType) {
      case 'Consumer': { // only applicable to consumer finance
        const applicant = (application.Individuals ?? []).find(i => i.Role === 'Applicant');
        if (applicant) {
          return applicant.PrivacyConsent;
        }
      }
    }
  }
  return false;
};
export const applicationToApplicantPropertyOwnerWithAddress = (application: Application): PropertyOwnerWithAddressValue => {
  if (application) {
    switch(application.ApplicationType) {
      case 'Consumer': { // only applicable to consumer finance
        const applicant = (application.Individuals ?? []).find(i => i.Role === 'Applicant');
        if (applicant) {
          const t: PropertyOwnerWithAddressValue = {
            propertyOwner: applicant.PropertyOwnerFlag,
            address: applicant.AddressofPropertyOwned ? {
              address: applicant.AddressofPropertyOwned.UnformattedAddress,
              StreetNumber: applicant.AddressofPropertyOwned.StreetNumber,
              StreetName: applicant.AddressofPropertyOwned.StreetName,
              StreetType: applicant.AddressofPropertyOwned.StreetType,
              Suburb: applicant.AddressofPropertyOwned.Suburb,
              State: applicant.AddressofPropertyOwned.State,
              UnitNumber: applicant.AddressofPropertyOwned.UnitNumber,
              Postcode: applicant.AddressofPropertyOwned.Postcode,
            } : undefined,
            propertyValue: applicant.PropertyValue ? (applicant.PropertyValue) : undefined,
            mortgageValue: applicant.MortgageBalance ? (applicant.MortgageBalance): undefined,
          }
          return t;
        }
      }
    }
  }
  return null;
};
export const applicationToApplicantEmail = (application: Application): string | null => {
  if (application) {
    switch(application.ApplicationType) {
      case 'Consumer': { // only applicable to consumer finance
        const applicant = (application.Individuals ?? []).find(i => i.Role === 'Applicant');
        if (applicant) {
          return applicant.Email;
        }
      }
    }
  }
  return null;
};
export const applicationToApplicantMobile = (application: Application): string | null => {
  if (application) {
    switch(application.ApplicationType) {
      case 'Consumer': { // only applicable to consumer finance
        const applicant = (application.Individuals ?? []).find(i => i.Role === 'Applicant');
        if (applicant) {
          return applicant.MobileNumber;
        }
      }
    }
  }
  return null;
};
export const applicationToApplicantMaritalStatus = (application: Application): MaritialStatusSelectionValue => {
  if (application) {
    switch(application.ApplicationType) {
      case 'Consumer': { // only applicable to consumer finance
        const applicant = (application.Individuals ?? []).find(i => i.Role === 'Applicant');
        if (applicant) {
          const m = applicant.MaritalStatus
          const op = MaritialStatusOptions.find(opt => _.toLower(opt.name) === _.toLower(m));
          return op ? op : null;
        }
      }
    }
  }
  return null;
};
export const applicationToApplicantNumberOfDependeants = (application: Application): number | null => {
  if (application) {
    switch(application.ApplicationType) {
      case 'Consumer': { // only applicable to consumer finance
        const applicant = (application.Individuals ?? []).find(i => i.Role === 'Applicant');
        if (applicant) {
          return applicant.NumberofDependants;
        }
      }
    }
  }
  return null;
};
export const applicationToApplicantEmploymentStatus = (application: Application): EmploymentStatusSelectionValue => {
  if (application) {
    switch(application.ApplicationType) {
      case 'Consumer': { // only applicable to consumer finance
        const applicant = (application.Individuals ?? []).find(i => i.Role === 'Applicant');
        if (applicant) {
          const op = EmploymentStatusOptions.find(opt => _.toLower(opt.name) === _.toLower(applicant.EmploymentStatus));
          return op ? op : null;
        }
      }
    }
  }
  return null;
};
export const applicationToApplicantRegularIncome = (application: Application): IncomeSelectionValue => {
  if (application) {
    switch(application.ApplicationType) {
      case 'Consumer': { // only applicable to consumer finance
        const applicant = (application.Individuals ?? []).find(i => i.Role === 'Applicant');
        if (applicant) {
          const incomePeriod = incomePeriodOptions.find(opt => _.toLower(opt.type) === _.toLower(applicant.IncomeFrequency));
          const income = applicant.Income;
          return incomePeriod ? {
            income, period: incomePeriod
          } : null;
        }
      }
    }
  }
  return null;
};
export const applicationToApplicantEmployer = (application: Application): EmployerValue => {
  if (application) {
    switch(application.ApplicationType) {
      case 'Consumer': { // only applicable to consumer finance
        const applicant = (application.Individuals ?? []).find(i => i.Role === 'Applicant');
        if (applicant) {
          const employer = applicant.Employer;
          const emplyerContact = applicant.EmployerContact;
          const timeAtEmployment = applicant.TimeatCurrentEmployment ?? 0;
          const prevEmployer = applicant.PreviousEmployer;
          const prevEmployerContact = applicant.PreviousEmployerContact;

          return {
            currentEmployer: employer,
            currentEmployerContact: emplyerContact,
            yearsAtCurrentEmployer: parseInt(String(timeAtEmployment / 12)),
            monthsAtCurrentEmployer: (timeAtEmployment % 12),
            previousEmployer: prevEmployer,
            previousEmployerContact: prevEmployerContact,
          };
        }
      }
    }
  }
  return null;
};

export const applicationToApplicantGuarantor = (application: Application): boolean | null => {
  if (application) {
    switch(application.ApplicationType) {
      case 'Consumer': { // only applicable to consumer finance
        const applicant = (application.Individuals ?? []).find(i => i.Role === 'Applicant');
        if (applicant) {
          return applicant.GuarantorFlag;
        }
      }
    }
  }
  return null;
};

export const applicationToDocFee = (application: Application): number | null => {
  if (application) {
    switch (application.ApplicationType) {
      case 'AssetFinance':
      case 'Consumer':   // only applicable to consumer finance
      case 'BusinessLoans':
      case 'CorporateLoans':
      case 'BusinessOverdraft':
        return application.PricingDetails.DocFee;
    }
  }
  return null;
}

export const applicationToInterestRate = (application: Application): string | null => {
  if (application) {
    switch (application.ApplicationType) {
      case 'AssetFinance':
      case 'Consumer':   // only applicable to consumer finance
        return String(application.PricingDetails.Rate);
    }
  }
  return null;
}

export const applicationToMargin = (application: Application): number | null => {
  if (application) {
    switch (application.ApplicationType) {
      case 'BusinessOverdraft':
      case 'CorporateLoans':
        return application.PricingDetails.Margin;
    }
  }
  return null;
}

export const applicationToSpouseTitle = (application: Application): TitleSelectionValue => {
  if (application) {
    switch (application.ApplicationType) {
      case 'Consumer':
        const spouse = (application.Individuals ?? []).find(i => i.Role === 'Spouse');
        if (spouse) {
          const t = TitleOptions.find(opt => _.toLower(opt.type) === _.toLower(spouse.Title));
          return t ? t : null;
        }
    }
  }
  return null;
}
export const applicationToSpouseFirstName = (application: Application): string | null => {
  if (application) {
    switch (application.ApplicationType) {
      case 'Consumer':
        const spouse = (application.Individuals ?? []).find(i => i.Role === 'Spouse');
        if (spouse) {
          const t = spouse.GivenName;
          return t ? t : null;
        }
    }
  }
  return null;
}
export const applicationToSpouseLastName = (application: Application): string | null => {
  if (application) {
    switch (application.ApplicationType) {
      case 'Consumer':
        const spouse = (application.Individuals ?? []).find(i => i.Role === 'Spouse');
        if (spouse) {
          const t = spouse.SurName;
          return t ? t : null;
        }
    }
  }
  return null;
}
export const applicationToSpouseDob = (application: Application): Moment | null => {
  if (application) {
    switch (application.ApplicationType) {
      case 'Consumer':
        const spouse = (application.Individuals ?? []).find(i => i.Role === 'Spouse');
        if (spouse) {
          return spouse.DoB ? moment(spouse.DoB, 'YYYY-MM-DD') : null;
        }
    }
  }
  return null;
}
export const applicationToSpouseGender = (application: Application): GenderValue => {
  if (application) {
    switch (application.ApplicationType) {
      case 'Consumer':
        const spouse = (application.Individuals ?? []).find(i => i.Role === 'Spouse');
        if (spouse) {
          return _.toLower(spouse.Gender) === 'm' ? 'Male' : 'Female';
        }
    }
  }
  return null;
}
export const applicationToSpouseEmail = (application: Application): string | null => {
  if (application) {
    switch (application.ApplicationType) {
      case 'Consumer':
        const spouse = (application.Individuals ?? []).find(i => i.Role === 'Spouse');
        if (spouse) {
          return spouse.Email;
        }
    }
  }
  return null;
}
export const applicationToSpouseMobile = (application: Application): string | null => {
  if (application) {
    switch (application.ApplicationType) {
      case 'Consumer':
        const spouse = (application.Individuals ?? []).find(i => i.Role === 'Spouse');
        if (spouse) {
          return spouse.MobileNumber;
        }
    }
  }
  return null;
}
export const applicationToSpouseEmploymentStatus = (application: Application): EmploymentStatusSelectionValue => {
  if (application) {
    switch (application.ApplicationType) {
      case 'Consumer':
        const spouse = (application.Individuals ?? []).find(i => i.Role === 'Spouse');
        if (spouse) {
          const op = EmploymentStatusOptions.find(opt => _.toLower(opt.name) === _.toLower(spouse.EmploymentStatus));
          return op ? op : null;
        }
    }
  }
  return null;
}
export const applicationToSpouseIncome = (application: Application): IncomeSelectionValue => {
  if (application) {
    switch (application.ApplicationType) {
      case 'Consumer':
        const spouse = (application.Individuals ?? []).find(i => i.Role === 'Spouse');
        if (spouse) {
          const incomePeriod = incomePeriodOptions.find(opt => _.toLower(opt.type) === _.toLower(spouse.IncomeFrequency));
          const income = spouse.Income;
          return incomePeriod ? {
            income, period: incomePeriod
          } : null;
        }
    }
  }
  return null;
}
export const applicationToSpouseEmployerName = (application: Application): string | null => {
  if (application) {
    switch (application.ApplicationType) {
      case 'Consumer':
        const spouse = (application.Individuals ?? []).find(i => i.Role === 'Spouse');
        if (spouse) {
          return spouse.Employer;
        }
    }
  }
  return null;
}
export const applicationToSpouseEmployerContact = (application: Application): string | null => {
  if (application) {
    switch (application.ApplicationType) {
      case 'Consumer':
        const spouse = (application.Individuals ?? []).find(i => i.Role === 'Spouse');
        if (spouse) {
          return spouse.EmployerContact;
        }
    }
  }
  return null;
}
export const applicationToSpouseGuarantor = (application: Application): boolean => {
  if (application) {
    switch (application.ApplicationType) {
      case 'Consumer':
        const spouse = (application.Individuals ?? []).find(i => i.Role === 'Spouse');
        if (spouse) {
          return !!spouse.GuarantorFlag;
        }
    }
  }
  return false;
}
export const applicationToSpousePrivacyConsent = (application: Application): boolean => {
  if (application) {
    switch (application.ApplicationType) {
      case 'Consumer':
        const spouse = (application.Individuals ?? []).find(i => i.Role === 'Spouse');
        if (spouse) {
          return !!spouse.PrivacyConsent;
        }
    }
  }
  return false;
}

export const applicationToApplicantHomeLoansAmount = (application: Application): number | null => {
  if (application) {
    switch(application.ApplicationType) {
      case 'Consumer':
        return application?.Expense?.HomeLoans ?? null;
    }
  }
  return null;
}
export const applicationToApplicantElectricityAmount = (application: Application): number | null => {
  if (application) {
    switch(application.ApplicationType) {
      case 'Consumer':
        return application?.Expense?.Electricity ?? null;
    }
  }
  return null;
};
export const applicationToApplicantCarLoansAmount = (application: Application): number | null => {
  if (application) {
    switch(application.ApplicationType) {
      case 'Consumer':
        return application?.Expense?.CarLoans ?? null;
    }
  }
  return null;
};
export const applicationToApplicantOtherUtilitiesAmount = (application: Application): number | null => {
  if (application) {
    switch(application.ApplicationType) {
      case 'Consumer':
        return application?.Expense?.OtherUtilities ?? null;
    }
  }
  return null;
};
export const applicationToApplicantPersonalLoansAmount = (application: Application): number | null => {
  if (application) {
    switch(application.ApplicationType) {
      case 'Consumer':
        return application?.Expense?.PersonalLoans ?? null;
    }
  }
  return null;
};
export const applicationToApplicantEducationAmount = (application: Application): number | null => {
  if (application) {
    switch(application.ApplicationType) {
      case 'Consumer':
        return application?.Expense?.Education ?? null;
    }
  }
  return null;
};
export const applicationToApplicantTotalCreditCardLimit = (application: Application): number | null => {
  if (application) {
    switch(application.ApplicationType) {
      case 'Consumer':
        return application?.Expense?.CreditCardLimit ?? null;
    }
  }
  return null;
};
export const applicationToApplicantGroceriesAmount = (application: Application): number | null => {
  if (application) {
    switch(application.ApplicationType) {
      case 'Consumer':
        return application?.Expense?.Groceries ?? null;
    }
  }
  return null;
};
export const applicationToApplicantOtherLoansAmount = (application: Application): number | null  => {
  if (application) {
    switch(application.ApplicationType) {
      case 'Consumer':
        return application?.Expense?.OtherLoans ?? null;
    }
  }
  return null;
};
export const applicationToApplicantInsuranceAmount = (application: Application): number | null => {
  if (application) {
    switch(application.ApplicationType) {
      case 'Consumer':
        return application?.Expense?.Insurance ?? null;
    }
  }
  return null;
};

export const applicationToApplicantShareOfMonthlyLivingExpanses = (application: Application): number | null => {
  if (application) {
    switch(application.ApplicationType) {
      case 'Consumer':
        return application?.Expense?.ShareofExpense ?? null;
    }
  }
  return null;
}

export const applicationToPolicies = (application: Application): PolicyValue => {
  if (application) {
    switch(application.ApplicationType) {
      case 'InsurancePremium': {
        if (application.Policies) {
          const policyValue: NonNullable<PolicyValue> = {
            policies: [],
            total: 0,
            paidByMonthlyInstallment: '10',
            monthlyInstallment: 0,
            applicationFee: 0,
            brokerage: 0,
          };
          (application?.Policies ?? []).forEach(p => {
            const coverType = CoverTypeOptions.find(opt => _.toLower(opt.ProductName) === _.toLower(p.typeOfCover));
            const policy: Policy = {
              coverType: coverType ? coverType : null,
              policyNumber: p.policyNumber,
              expiryDate: p.expiryDate ? moment(p.expiryDate, 'YYYY-MM-DD') : moment(),
              inceptionDate: p.inceptionDate ? moment(p.inceptionDate, 'YYYY-MM-DD') : moment(),
              invoiceNumber: p.invoiceNumber,
              premiumAmount: Number(p.premiumAmount),
              insurer: p.insurer,
            }
            policyValue.policies.push(policy);
          });
          return policyValue;
        }
      }
    }
  }
  return null;
}


export const applicationToAuthorisedSignatories = (application: Application): AuthorisedSignatoryValue => {
  if (application) {
    switch(application.ApplicationType) {
      case 'InsurancePremium': {
        const as: NonNullable<AuthorisedSignatoryValue> = [];
        if (application.AuthorisedSignatory && application.AuthorisedSignatory.length) {
          application.AuthorisedSignatory.forEach(a => {
            const title = TitleOptions.find(opt => _.toLower(opt.type) === _.toLower(a.Title));
            const role =  authorisedSignatoryRoleOptions.find(opt => _.toLower(opt.name) === _.toLower(a.Role));
            const aus: NonNullable<AuthorisedSignatoryValue>[number] = {
              title: title ?? null,
              firstName: a.GivenName ?? '',
              lastName: a.SurName ?? '',
              email: a.Email ?? '',
              mobile: a.MobileNumber ?? '',
              middleName: a.MiddleName ?? '',
              role: role ?? null,
            }
            as.push(aus);
          });
        }
        return as;
      }
    }
  }
  return null;
}


export const applicationToApplicationNotes = (application: Application): string | null => {
  if (application) {
    switch (application.ApplicationType) {
      case 'AssetFinance':
      case 'BusinessLoans':
      case 'BusinessOverdraft':
      case 'InsurancePremium':
      case 'Commercial':
      case 'TradeFinance':
      case 'CorporateLoans':
      case 'Consumer': {
        return application.ApplicationNotes ?? null;
      }
    }
  }
  return null;
}

export const applicationToLimitRequest = (application: Application): number | null => {
  if (application) {
    switch (application.ApplicationType) {
      case 'TradeFinance':
      case 'CorporateLoans':
        return application.PricingDetails.LimitRequest;
    }
  }
  return null;
}

export const applicationToWhatBusinessSell = (application: Application): string | null => {
  if (application) {
    switch (application.ApplicationType) {
      case 'TradeFinance':
      case 'CorporateLoans':
        return application.AppInfo.BusinessSell;
    }
  }
  return null;
};
export const applicationToWhatBusinessBuy = (application: Application): string | null => {
  if (application) {
    switch (application.ApplicationType) {
      case 'TradeFinance':
      case 'CorporateLoans':
        return application.AppInfo.BusinessDescription;
    }
  }
  return null;
};
export const applicationToWhatBussinessSellTo = (application: Application): string | null => {
  if (application) {
    switch (application.ApplicationType) {
      case 'TradeFinance':
      case 'CorporateLoans':
        return application.AppInfo.BusinessSellTerms;
    }
  }
  return null;
};


export const individualTitle = (individual: Individual): TitleSelectionValue => {
  if (individual) {
    const v = TitleOptions.find(opt => _.toLower(opt.type) === _.toLower(individual.Title));
    return v ? v : null;
  }
  return null;
}

export const individualDob = (individual: Individual): Moment | null => {
  if (individual) {
    return moment(individual.DoB, 'YYYY-MM-DD');
  }
  return null;
}

export const individualResidentialAddress = (individual: Individual): Address2ComponentValue => {
  if (individual && individual.HomeAddress) {
    const parsedAddress = parseRawAddress(individual.HomeAddress.UnformattedAddress);
    return {
      address: individual.HomeAddress?.UnformattedAddress ?? '',
      UnitNumber: parsedAddress?.unit_number ?? '',
      StreetNumber: individual.HomeAddress.StreetNumber ? individual.HomeAddress.StreetNumber : (parsedAddress?.street_number ?? ''),
      StreetName: individual.HomeAddress.StreetName ? individual.HomeAddress.StreetName : (parsedAddress?.street_name ?? ''),
      StreetType: individual.HomeAddress.StreetType ? individual.HomeAddress.StreetType : (parsedAddress?.street_type ?? ''),
      Suburb: individual.HomeAddress.Suburb ? individual.HomeAddress.Suburb : (parsedAddress?.locality ?? ''),
      State: individual.HomeAddress.State ? individual.HomeAddress.State : (parsedAddress?.region ?? ''),
      Postcode: individual.HomeAddress.Postcode ? individual.HomeAddress.Postcode : (parsedAddress?.postal_code ?? ''),
    };
  }
  return null;
}

export const applicantsToPrimaryContact = (applicants: ApplicationApplicant): ContactValue[] => {
  const contacts: ContactValue[] = [];
  if (applicants) {
    if (Array.isArray(applicants)) {
      for (const applicant of applicants) {
        const c = applicantToPrimaryContact(applicant);
        if (c) {
          contacts.push(c);
        }
      }
    } else {
      const applicant = applicants;
      const c = applicantToPrimaryContact(applicant);
      if (c) {
        contacts.push(c);
      }
    }
  }
  return contacts;
}

export const applicantToPrimaryContact = (applicant: IndividualTrustee | EntityTrustee | IndividualMember | EntityMember | IndividualPartner | EntityPartner | SoleTraderValue | DirectorValueWithPropertyOwnerAddress | DirectorValueWithoutPropertyOwnerAddress): ContactValue | null  => {
  if (applicant) {
    switch(applicant.kind) {
      case 'Member': {
        switch(applicant.type) {
          case 'Individual':
            const c: ContactValue = {
              isManual: false,
              email: applicant.email,
              mobileNumber: applicant.mobileNumber,
              firstName: applicant.firstName,
              lastName: applicant.lastName,
              title: applicant.title,
              telephone: applicant.mobileNumber,
              areaCode: applicant.mobileNumber.substr(0, 2) ?? '',
            }
            return c;
        }
        break;
      }
      case 'Director': {
        switch(applicant.type) {
          case 'WithoutPropertyAddress':
          case 'WithPropertyAddress': {
            const c: ContactValue = {
              isManual: false,
              email: applicant.email,
              mobileNumber: applicant.mobileNumber,
              firstName: applicant.firstName,
              lastName: applicant.lastName,
              title: applicant.title,
              telephone: applicant.mobileNumber,
              areaCode: applicant.mobileNumber.substr(0, 2) ?? '',
            }
            return c;
          }
        }
        break;
      }
      case 'Partner': {
        switch(applicant.type) {
          case 'Individual': {
            const c: ContactValue = {
              isManual: false,
              email: applicant.email,
              mobileNumber: applicant.mobileNumber,
              firstName: applicant.firstName,
              lastName: applicant.lastName,
              title: applicant.title,
              telephone: applicant.mobileNumber,
              areaCode: applicant.mobileNumber.substr(0, 2) ?? '',
            }
            return c;
          }
        }
        break;
      }
      case 'SoleTrader': {
        const c: ContactValue = {
          isManual: false,
          email: applicant.email,
          mobileNumber: applicant.mobile,
          firstName: applicant.firstName,
          lastName: applicant.lastName,
          title: applicant.title,
          telephone: applicant.mobile,
          areaCode: applicant.mobile.substr(0, 2) ?? '',
        }
        return c;
      }
      case 'Trustee': {
        switch(applicant.type) {
          case 'Individual': {
            const c: ContactValue = {
              isManual: false,
              email: applicant.email,
              mobileNumber: applicant.mobileNumber,
              firstName: applicant.firstName,
              lastName: applicant.lastName,
              title: applicant.title,
              telephone: applicant.mobileNumber,
              areaCode: applicant.mobileNumber.substr(0, 2) ?? '',
            }
            return c;
          }
        }
        break;
      }
    }
  }
  return null;
}

export const isTrustApplication = (application: Application) => {
  const primaryEntity = application.CommercialEntities.find(entity => entity.Type === 'Primary');
  return primaryEntity?.EntityType === 'TRST';
}

export const isPartnershipApplication = (application: Application) => {
  const primaryEntity = application.CommercialEntities.find(entity => entity.Type === 'Primary');
  return primaryEntity?.EntityType === 'PTNR';
}

export const applicationDefaultDocuments = (application: Application, includeOptional = false): DocumentTag[] => {
  const applicant = application.Individuals.find(i => i.Role === "Applicant");
  const primaryEntity = application.CommercialEntities.find(e => e.Type === "Primary");
  return defaultDocuments(
    application.ApplicationType,
    application.Individuals.filter(i => i.Role === "Guarantor").map(i => i.GivenName + ' ' + i.SurName),
    application.ApplicationType === "Consumer" ? applicant?.GivenName + " " + applicant?.SurName : (primaryEntity?.LegalName ?? ""),
    "",
    "",
    (application.PricingDetails as any).LoanAmount,
    (application.PricingDetails as any).Deposit,
    (application.PricingDetails as any).PrivateSale === "Yes",
    isTrustApplication(application),
    isPartnershipApplication(application),
    includeOptional
  )
}

//////////////////// Documentworklist
export const filterDocumentworklist = (filter: DocumentWorklistCategory, worklist: DocumentWorklists): DocumentWorklists => {
  switch (filter) {
    case "credit":
      return worklist.filter(w => ["Submitted to Credit", "More Information", "Waiting for Bank Statements"].includes(w.stage));
    case "client-services":
      return worklist.filter(w => ["Credit Approved", "Deal Prep", "QA", "Settlement", "Closed Won"].includes(w.stage));
    case "settlements":
      return worklist.filter(w => ["Documentation", "Docs Out", "Settlement Pending"].includes(w.stage));
    default:
      return worklist;
  }
}

///////////////////////////////////////////////////////
///// Inteflow
//////////////////////////////////////
export const fromApplicantToInteflowIndividualsData = (applicants: ApplicationApplicant): SaveApplicationData['Individuals'] => {
  const individualApplicants = applicantsThatAreIndividual(applicants);
  const otherIndividuals = [];
  for (const individualApplicant of individualApplicants) {
    let privacyConsented = false;
    let propertyOwner = false;
    let propertyValue = undefined;
    let middleName = undefined;
    let mortgageBalance = undefined;
    let addressOfPropertyOwned = undefined;
    let mobileNumber = undefined;

    switch(individualApplicant.kind) {
      case 'Trustee':
      case 'Partner':
      case 'Member': {
        middleName = individualApplicant.middleName;
        privacyConsented = !!individualApplicant.privacyConsentObtained;
        mobileNumber = individualApplicant.mobileNumber;
        const p: PropertyOwnerWithAddressValue = individualApplicant.propertyOwner;
        if (p) {
          propertyOwner = p.propertyOwner;
          propertyValue = p.propertyValue;
          mortgageBalance = p.mortgageValue;
          addressOfPropertyOwned = p.address?.address ? {
            UnformattedAddress: p.address.address,
            UnitNumber: p.address?.UnitNumber ?? '',
            StreetNumber: p.address?.StreetNumber ?? '',
            StreetName: p.address?.StreetName ?? '',
            StreetType: p.address?.StreetType ?? '',
            Suburb: p.address?.Suburb ?? '',
            State: p.address?.State ?? '',
            Postcode: p.address?.Postcode ?? '',
          } :  undefined;
        }
        break;
      }
      case 'SoleTrader': {
        privacyConsented = !!individualApplicant.privacyConsentObtained;
        mobileNumber = individualApplicant.mobile;
        const p = individualApplicant.propertyOwner;
        if (p) {
          propertyOwner = p.propertyOwner;
          propertyValue = p.propertyValue;
          mortgageBalance = p.mortgageValue;
          addressOfPropertyOwned = p.address?.address ? {
            UnformattedAddress: p.address.address,
            UnitNumber: p.address?.UnitNumber ?? '',
            StreetNumber: p.address?.StreetNumber ?? '',
            StreetName: p.address?.StreetName ?? '',
            StreetType: p.address?.StreetType ?? '',
            Suburb: p.address?.Suburb ?? '',
            State: p.address?.State ?? '',
            Postcode: p.address?.Postcode ?? '',
          } : undefined;
        }
        break;
      }
      case 'Director': {
        console.log('*&&&&&&&&&&&&&&&&&&&&&&&&& director', individualApplicant);
        privacyConsented = !!individualApplicant.privacyConsentObtained;
        mobileNumber = individualApplicant.mobileNumber;
        const p = individualApplicant.propertyOwner;
        if (p && (typeof (p as any)['propertyOwner']) === 'boolean') {
          propertyOwner = (p as Exclude<PropertyOwnerWithAddressValue, null>).propertyOwner;
          propertyValue = (p as Exclude<PropertyOwnerWithAddressValue, null>).propertyValue;
          mortgageBalance = (p as Exclude<PropertyOwnerWithAddressValue, null>).mortgageValue;
          addressOfPropertyOwned = (p as Exclude<PropertyOwnerWithAddressValue, null>).address?.address ?
            {
              UnformattedAddress: (p as Exclude<PropertyOwnerWithAddressValue, null>).address!.address,
              UnitNumber: (p as NotNullable<PropertyOwnerWithAddressValue>).address?.UnitNumber ?? '',
              StreetNumber: (p as NotNullable<PropertyOwnerWithAddressValue>).address?.StreetNumber ?? '',
              StreetName: (p as NotNullable<PropertyOwnerWithAddressValue>).address?.StreetName ?? '',
              StreetType: (p as NotNullable<PropertyOwnerWithAddressValue>).address?.StreetType ?? '',
              Suburb: (p as NotNullable<PropertyOwnerWithAddressValue>).address?.Suburb ?? '',
              State: (p as NotNullable<PropertyOwnerWithAddressValue>).address?.State ?? '',
              Postcode: (p as NotNullable<PropertyOwnerWithAddressValue>).address?.Postcode ?? '',
            } :  undefined;
        } else {
          propertyOwner = (p as boolean);
        }
        break;
      }
    }
    const individual = {
      id: v4(),
      Title: individualApplicant.title?.type ?? undefined,
      GivenName: individualApplicant.firstName,
      MiddleName: middleName ?? '',
      SurName: individualApplicant.lastName,
      DoB: individualApplicant.dob ? individualApplicant.dob.format('YYYY-MM-DD') : undefined,  // YYYY-MM-DD
      Gender: toInteflowGender(individualApplicant.gender),
      PrivacyConsent: privacyConsented,
      PropertyOwnerFlag: propertyOwner,
      PropertyValue: propertyValue !== null ? propertyValue : 0,
      MortgageBalance: mortgageBalance !== null ? mortgageBalance : 0,
      AddressofPropertyOwned: addressOfPropertyOwned,
      GuarantorFlag: individualApplicant.guarantor ?? false,
      Email: individualApplicant.email,
      MobileNumber:  mobileNumber,
      deleted: false,
      HomeAddress: (individualApplicant.residentialAddress?.address) ? ({
        UnformattedAddress: individualApplicant.residentialAddress?.address,
        UnitNumber: (individualApplicant).residentialAddress?.UnitNumber ?? '',
        StreetNumber: (individualApplicant).residentialAddress?.StreetNumber ?? '',
        StreetName: (individualApplicant).residentialAddress?.StreetName ?? '',
        StreetType: (individualApplicant).residentialAddress?.StreetType ?? '',
        Suburb: (individualApplicant).residentialAddress?.Suburb ?? '',
        State: (individualApplicant).residentialAddress?.State ?? '',
        Postcode: (individualApplicant).residentialAddress?.Postcode ?? '',
      }) : undefined,
      Role: individualApplicant.kind === 'Member' ? 'Trustee' : individualApplicant.kind,
      SignerRole: (individualApplicant.guarantor ?? false) ? 'GuarantorSigner' as const  : 'Signer' as const,
      DriverLicenseExpiryDt: undefined,
      DriverLicenseState: undefined,
    }
    otherIndividuals.push(individual);
  }

  return otherIndividuals;
}


export const fromGuarantorToInteflowIndividuals = (guarantors: GuarantorValue): SaveApplicationData['Individuals'] => {
  const otherIndividuals = [];
  if (guarantors) {
    const individualGuarantors = guarantorsThatAreIndividual(guarantors);
    for (const individualGuarantor of individualGuarantors) {
      let propertyOwner = false;
      let propertyValue = undefined;
      let middleName = undefined;
      let mortgageBalance = undefined;
      let addressOfPropertyOwned = undefined;
      const p = individualGuarantor.propertyOwner;
      if (p && ((p as any)['propertyOwner'])) {
        propertyOwner = (p as Exclude<PropertyOwnerWithAddressValue, null>).propertyOwner;
        propertyValue = (p as Exclude<PropertyOwnerWithAddressValue, null>).propertyValue;
        mortgageBalance = (p as Exclude<PropertyOwnerWithAddressValue, null>).mortgageValue;
        addressOfPropertyOwned = (p as Exclude<PropertyOwnerWithAddressValue, null>).address?.address ?
          {
            UnformattedAddress: (p as Exclude<PropertyOwnerWithAddressValue, null>).address!.address,
            UnitNumber: (p as NotNullable<PropertyOwnerWithAddressValue>).address!.UnitNumber,
            StreetNumber: (p as NotNullable<PropertyOwnerWithAddressValue>).address!.StreetNumber,
            StreetName: (p as NotNullable<PropertyOwnerWithAddressValue>).address!.StreetName,
            StreetType: (p as NotNullable<PropertyOwnerWithAddressValue>).address!.StreetType,
            Suburb: (p as NotNullable<PropertyOwnerWithAddressValue>).address!.Suburb,
            State: (p as NotNullable<PropertyOwnerWithAddressValue>).address!.State,
            Postcode: (p as NotNullable<PropertyOwnerWithAddressValue>).address!.Postcode,
            IsManualInput: false,
          } : undefined;
      }
      const individual = {
        id: v4(),
        Title: individualGuarantor.title?.type ?? undefined,
        GivenName: individualGuarantor.firstName ?? undefined,
        MiddleName: individualGuarantor.middleName ?? '',
        SurName: individualGuarantor.lastName ?? undefined,
        DoB: individualGuarantor.dob ? individualGuarantor.dob.format('YYYY-MM-DD') : undefined,  // YYYY-MM-DD
        Gender: toInteflowGender(individualGuarantor.gender),
        PrivacyConsent: individualGuarantor.privacyConsentObtained,
        PropertyOwnerFlag: propertyOwner,
        PropertyValue: propertyValue,
        MortgageBalance: mortgageBalance,
        AddressofPropertyOwned: addressOfPropertyOwned,
        GuarantorFlag: true,
        Email: individualGuarantor.email ?? undefined,
        MobileNumber:  individualGuarantor.mobileNumber ?? undefined,
        deleted: false,
        HomeAddress: individualGuarantor.residentialAddress?.address ? {
          UnformattedAddress: individualGuarantor.residentialAddress?.address,
          UnitNumber: individualGuarantor.residentialAddress?.UnitNumber,
          StreetNumber: individualGuarantor.residentialAddress?.StreetNumber,
          StreetName: individualGuarantor.residentialAddress?.StreetName,
          StreetType: individualGuarantor.residentialAddress?.StreetType,
          Suburb: individualGuarantor.residentialAddress?.Suburb,
          State: individualGuarantor.residentialAddress?.State,
          Postcode: individualGuarantor.residentialAddress?.Postcode,
          IsManualInput: false,
        } : undefined,
        DriverLicenseExpiryDt: undefined,
        DriverLicenseState: undefined,
        Role: 'Guarantor' as const,
        SignerRole: 'Guarantor' as const,
      }
      otherIndividuals.push(individual);
    }
  }
  return otherIndividuals;
}


export const fromApplicantToInteflowCommercialEntities = (applicants: ApplicationApplicant): Exclude<SaveApplicationData['CommercialEntities'], undefined> => {
  const entityApplicants = applicantsThatAreEntity(applicants);
  const otherCommercialEntity = [];
  for (const entityApplicant of entityApplicants) {
    const commercialEntity = {
      LegalName: entityApplicant.organisation?.name ?? undefined,
      ABN: entityApplicant.abn ?? undefined,
      ACN: _.isEmpty(entityApplicant.acn) ? '0' : entityApplicant.acn,
      Type: entityApplicant.kind,
      TradingName: entityApplicant?.organisation?.name ?? undefined,
      EntityType:  entityApplicant.organisationType ? toInteflowEntityTypes(entityApplicant.organisationType.type) : undefined,
      manualEntry: entityApplicant.manualEntry ?? false,
      YearsInBusiness: undefined, // ???
    }

    otherCommercialEntity.push(commercialEntity);
  }
  return otherCommercialEntity;
}

export const fromGuarantorToInteflowCommercialEnttities = (guarantors: GuarantorValue): Exclude<SaveApplicationData['CommercialEntities'], undefined> => {
  const otherCommercialEntity = [];
  if (guarantors) {
    const entityGuarantors = guarantorsThatAreEntity(guarantors);
    for (const entityGuarantor of entityGuarantors) {
      const commercialEntity = {
        LegalName: entityGuarantor.organisation?.name ?? undefined,
        ABN: entityGuarantor.abn ?? undefined,
        ACN: entityGuarantor.acn ?? undefined,
        Type: 'Guarantor',
        TradingName: entityGuarantor?.organisation?.name ?? undefined,
        EntityType:  entityGuarantor.organisationType ? toInteflowEntityTypes(entityGuarantor.organisationType.type) : undefined,
        YearsInBusiness: undefined, // ???
        manualEntry: false,
      }
      otherCommercialEntity.push(commercialEntity);
    }
  }
  return otherCommercialEntity;
}

export const fromReferenceToInteflowReferences = (refValue: ReferenceValue): Exclude<SaveApplicationData['Reference'], undefined> => {
  const references = [];
  if (refValue) {
    for (const ref of refValue) {
      const r = {
        CompanyName: ref.company ?? undefined,
        Telephone: ref.phoneNumber,
        ContactPerson: ref.contactPerson,
        Type: ref.referenceType?.name ?? undefined,
      };
      references.push(r);
    }
  }
  return references;
}

export const fromContactToInteflowContacts = (contact: ContactValue | null): SaveApplicationData['Contacts'] => {
  let telephone = contact?.telephone;
  let areaCode = contact?.areaCode;
  telephone = telephone ?  _.replace(telephone, /\s/g, '') : telephone;
  if (telephone && telephone.length >= 10) {
    telephone = telephone.substring(2).trim();
    areaCode = telephone.substring(0, 2).trim();
  }
  const apiBodyContact = {
    Title: contact?.title?.type ?? undefined,
    GivenName: contact?.firstName ?? undefined,
    SurName: contact?.lastName ?? undefined,
    Email: contact?.email ?? undefined,
    MiddleName: undefined,
    Telephone: (telephone && telephone != '' ? telephone : undefined),
    Mobile: contact?.mobileNumber ?? undefined,
    AreaCode: (areaCode && areaCode != '' ? areaCode : undefined),
    isManual: contact?.isManual ?? undefined
  }
  return apiBodyContact;
}

// ====================================
// === Trade Customer
// ====================================

export const showDueDate = (passDueDate: boolean, nextDueDate?: string): string => {
  if (passDueDate) {
    return 'Now';
  }
  return nextDueDate ? moment(nextDueDate).format('DD MMM Y') : '---';
}

export const showDueAmount = (passDueDate: boolean, balancePayable?: number, nextBalancePayable?: number): string => {
  if (passDueDate && balancePayable) {
    return '$' +(balancePayable).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    // return  `$${balancePayable}`;
  }
  if (!passDueDate && nextBalancePayable) {
    return '$' +(nextBalancePayable).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    // return `$${nextBalancePayable}`;
  }
  return '---';
}


// =========================
// === Digital ID
// =========================
export const isOnSuccessMessageAnError = (msg: OnCompleteMessage): msg is OnCompleteMessageError => {
  return  ((msg as OnCompleteMessageError).error !== undefined);
}

// ==========================
// ====   Accreditation tabs
// ==========================
// NOTE: moved to accreditation-details.component, it is only used there
// export const getAccreditationTabIndex = (tab: AccreditationSelectionType, isSupplier = false) => {
//   switch (tab) {
//     case 'acc':
//       return 0;
//     case 'drivingLicence':
//       return isSupplier ? 2 : 1;
//     case 'documents':
//       return 2;
//     default:
//       return 0;
//   }
// }

// ==========================
// ====   Accreditation tabs
// ==========================
export const hasMultipleRole = (user: Pick<PortalLoginUser, 'priviledges'>): boolean => {
  let roleCount = 0
  const priviledges = (user?.priviledges ?? []);
  roleCount += (  // all these are counted as 1
      priviledges.includes('admin') ||
      priviledges.includes('analyst') ||
      priviledges.includes('companyadmin') ||
      priviledges.includes('externalbroker') ||
      priviledges.includes('internalbroker') ||
      priviledges.includes('companyoperator') ||
      priviledges.includes('broker')||
      priviledges.includes('credit') ||
      priviledges.includes('operations') ||
      priviledges.includes('operations24') ||
      priviledges.includes('settlement') ||
      priviledges.includes('salesBDM') ||
      priviledges.includes('salesAM')
  ) ? 1 : 0;
  roleCount += priviledges.includes('trade') ? 1 : 0;
  // roleCount += priviledges.includes('floorplan') ? 1 : 0;
  roleCount += priviledges.includes('overdraft') ? 1 : 0;
  return (roleCount > 1)
}

export const hasAccessBrokerDashboard = (user: Pick<PortalLoginUser, 'priviledges'>): boolean => {
  const priviledges = (user?.priviledges ?? []);
  return (
    priviledges.includes('admin') ||
    priviledges.includes('analyst') ||
    priviledges.includes('companyadmin') ||
    priviledges.includes('externalbroker') ||
    priviledges.includes('internalbroker') ||
    priviledges.includes('companyoperator') ||
    priviledges.includes('broker') ||
    priviledges.includes('credit') ||
    priviledges.includes('operations') ||
    priviledges.includes('settlement') ||
    priviledges.includes('salesBDM') ||
    priviledges.includes('salesAM')
  );
}

export const hasAccessOverdraftDashboard = (user: Pick<PortalLoginUser, 'priviledges'>): boolean => {
  const priviledges = (user?.priviledges ?? []);
  return priviledges.includes('overdraft');
}

export const hasAccessCardOperatorDashboard = (user: Pick<PortalLoginUser, 'priviledges'>): boolean => {
  const priviledges = (user?.priviledges ?? []);
  return priviledges.includes('operations24');
}

// ==========================
// ====   Generate Contract
// ==========================
export const checkContractIndividuals = (application: Application, dialogService?: ApplicationDialogService): boolean => {
  const individuals = ((application.Individuals ?? []) as Individual[])
    .filter(i => i.deleted !== true)
  // .reduce((a: boolean, i: Individual)=>{
  //   const r = (!_.isEmpty(i.Email)) && (!_.isEmpty(i.MobileNumber));
  //   return (r && a);
  // }, true);
  const indvidualWithNoEmailOrMobile = individuals.filter(ind => _.isEmpty(ind.Email) || _.isEmpty(ind.MobileNumber))
  if (indvidualWithNoEmailOrMobile.length) {
    let names: string[] = []
    indvidualWithNoEmailOrMobile.map(ind => {
      names.push(ind.GivenName + ' ' + ind.SurName)
    })
    if(dialogService) {
      dialogService.openAlertDialog({
        message: `Missing contact info`,
        subMessage: `${names.join(', ')} has missing Email or Mobile Number`,
      });
    }
    return false;
  } else {
    const indWithSameEmailorPhone = [...new Set(individualHavingSameEmailOrPhone(individuals))]
    if (indWithSameEmailorPhone.length) {
      if (dialogService) {
        dialogService.openAlertDialog({
          message: `Duplicate Email or Mobile`,
          subMessage: `${indWithSameEmailorPhone.join(', ')} has duplicate Email or Mobile Number`,
        });
      }
      return false;
    }
    return true;
  }
}

export const individualHavingSameEmailOrPhone = (individuals: Individual[]) => {
  const contactMap = new Map();
  individuals.forEach((individual) => {
    const email = individual.Email;
    const phone = individual.MobileNumber;
    const name = individual.GivenName + ' ' + individual.SurName;
    if (email && name) {
      const names = contactMap.get(email) || [];
      contactMap.set(email, [...names, name]);
    }
    if (phone && name) {
      const names = contactMap.get(phone) || [];
      contactMap.set(phone, [...names, name]);
    }
  });
  console.log("concatmap", contactMap)
  const result: any = [];
  contactMap.forEach((names, contact) => {
    if (names.length > 1) {
      result.push(...names);
    }
  });
  return result;
}

export const displayGenerateContractButton = (user: Pick<PortalLoginUser, 'priviledges'> | null, productionMode: boolean): boolean => {
  // if (productionMode) {
  //   return isInternalUser(user);
  // } else {
    return isInternalUserOrBroker(user);
  // }
}


// ==========================
// ====   Individual and Entity Info
// ==========================
export const getEntityType = (result: BusinessNumberSearchResult[number]): EntityTypeValue => {
  if (result) {
    if (result['organisation-number']) {
      return { name: 'Company', type: 'company' } //eg 89643308267
    }
    if (result['australian-business-register-report'] && result['australian-business-register-report'].type == 'result') {
      const report = result['australian-business-register-report']
      if (report.EntityTypeInd && report.EntityTypeInd == 'IND') {
        return { name: 'SoleTrader', type: 'sole-trader' } //eg 94391035453
      }
      if (report.EntityTypeText.toLowerCase().includes('trust')) {
        return { name: 'Trust', type: 'trust' } //eg 43423767626
      }
      if (report.EntityTypeText.toLowerCase().includes('partnership')) {
        return { name: 'Partnership', type: 'partnership' } //eg 45663186604
      }
    }
  }
  return { name: 'Other', type: 'other' }
}

/**
 * Inform <code>store</code> when count has reduced to zero. Count can be reduced by <code>countDown()</code> function in ctx.
 * Eg.
 * <pre>
 *  const store = createAsyncStore();
 *  countDownLatch(store, 2, (ctx) => {
 *     // do some counting down
 *     setTimeout(()=> {
 *       ctx.countDown();
 *     }, 1000);
 *     setTimeout(()=> {
 *       ctx.countDown();
 *     }, 1000);
 *  });
 *
 *  Then, either
 *  store.value$.subscribe(()=> {
 *    // count has reached zero
 *  });
 *
 *  or ( in template )
 *  <div *ngIf="store.value$ | async; let state">
 *    <div *ngIf="state.loading"> loading (count has not reached zero yet) </div>
 *    <div *ngIf="!state.loading">count has reached zero</div>
 *  </div>
 * </pre>
 */
export const countDownLatch = (store: ReturnType<typeof createAsyncStore>, count: number, fn: (ctx: CountDownLatchContext)=>void, opts: {checkTimeout: number, maxCheck: number} = { checkTimeout: 200, maxCheck: 200}) => {
  let currentMaxCheck = 0;
  const context: CountDownLatchContext = {
    countDown: () => {
      count--;
    }
  }
  const checkCount = () => {
    setTimeout(()=> {
      currentMaxCheck++;
      // console.log('*=======', count, currentMaxCheck);
      if (count <= 0) {
        store.update({});
      } else if (currentMaxCheck >= opts.maxCheck) {
        store.update({});
      } else {
        checkCount();
      }
    }, opts.checkTimeout);
  }
  fn(context);
  checkCount();
}
export interface CountDownLatchContext {
  countDown: ()=>void;
}
