<div class="business-loans-calculator-page container-fluid">
<div class="calculator-content mt-4" [formGroup]="formGroupCalculator">
  <div class="section-format mt-1 p-4">
    <div class="row">
      <div class="col-md-10">
        <h3>Loan Purpose</h3>
      </div>
      <div class="col-md-2" fxLayoutAlign="end center"><span class="text-muted">1/5</span></div>
    </div>
    <div class="mat-body separator-bottom">Please select the purpose for loan.</div>
    <div class="row separator-bottom">
      <div class="col-lg-6 col-md-12">
        <loan-purpose-selection [formControl]="formControlLoanPurpose"
          [mark]="markTriggerSubject(formGroupCalculator)">
        </loan-purpose-selection>
      </div>
    </div>
  </div>

  <div class="section-format mt-1 p-4">
    <div class="row main">
      <div class="col-md-10">
        <h3>Finance details</h3>
      </div>
      <div class="col-md-2" fxLayoutAlign="end center"><span class="text-muted">2/5</span></div>
    </div>
    <div class="mat-body separator-bottom">Let us know your preferred repayment terms</div>
    <div class="row main">
      <div class="col-lg-3 col-md-12">
        <business-loan-terms-selection [formControl]="formControlLoanTerms"
          [mark]="markTriggerSubject(formGroupCalculator)">
        </business-loan-terms-selection>
      </div>
    </div>
    <div class="row main mt-3">
      <div class="col-lg-3 col-md-12">
        <brokerage-selection [formControl]="formControlBrokerage" [max]="4"
          [mark]="markTriggerSubject(formGroupCalculator)">
        </brokerage-selection>
      </div>
      <div class="col-lg-3 col-md-12">
        <payment-frequency-selection [filter]="['Monthly', 'Fortnightly']"
          [formControl]="formControlPaymentFrequency" [mark]="markTriggerSubject(formGroupCalculator)">
        </payment-frequency-selection>
      </div>
    </div>
  </div>

  <div class="section-format mt-1 p-4">
    <div class="row main">
      <div class="col-md-10">
        <h3>Risk profile</h3>
      </div>
      <div class="col-md-2" fxLayoutAlign="end center"><span class="text-muted">3/5</span></div>
    </div>
    <div class="mat-body separator-bottom row col-md-6">
    Please provide us with further details so we can provide you with a more accurate quote</div>

    <div class="row main">
      <div class="col-lg-3 col-md-12">
        <yes-no title="Property owner" [formControl]="formControlPropertyOwner"
          [mark]="markTriggerSubject(formGroupCalculator)">
        </yes-no>
      </div>
      <div class="col-lg-3 col-md-12">
        <yes-no title="Adverse on file" [formControl]="formControlAdverseOnFile"
          [mark]="markTriggerSubject(formGroupCalculator)">
        </yes-no>
      </div>
    </div>
    <div class="row main">
      <div class="col-lg-3 col-md-12">
        <yes-no title="Equifax Score above 550" [formControl]="formControlEquifaxScoreAboveThreshold"
          [mark]="markTriggerSubject(formGroupCalculator)">
        </yes-no>
      </div>
      @if (!formControlEquifaxScoreAboveThreshold.value) {
        <div class="col-lg-3 col-md-12">
          <yes-no title="Director Score 500 - 550" [formControl]="formControlDirectorScore"
            [mark]="markTriggerSubject(formGroupCalculator)">
          </yes-no>
        </div>
      }
    </div>
    <div class="row main">
      @if (!formControlPropertyOwner.value) {
        <div class="col-lg-3 col-md-12">
          <yes-no title="Has the business taken another commercial loan in the past 3 years?"
            [formControl]="formControlPreviousLoan" [notselected]="true"
            [mark]="markTriggerSubject(formGroupCalculator)">
          </yes-no>
        </div>
      }
    </div>
  </div>

  <div class="section-format mt-1 p-4">
    <div class="row main">
      <div class="col-md-10">
        <h3>ABN/GST details</h3>
      </div>
      <div class="col-md-2" fxLayoutAlign="end center"><span class="text-muted">4/5</span></div>
    </div>
    <div class="mat-body separator-bottom row col-md-6">
    Please provide us with your ABN/GST age</div>
    <div class="row main">
      <div class="col-lg-3 col-md-12">
        <yes-no title="ABN/GST age > 24 months" [formControl]="formControlAbnGstAgeAboveThreshold"
          [mark]="markTriggerSubject(formGroupCalculator)">
        </yes-no>
      </div>
    </div>
  </div>

  <div class="section-format mt-1 p-4">
    <div class="row main">
      <div class="col-md-10">
        <h3>Business Term Loan amount</h3>
      </div>
      <div class="col-md-2" fxLayoutAlign="end center"><span class="text-muted">5/5</span></div>
    </div>
    <div class="row main">
      <div class="col-lg-6 col-md-12">
        <currency-input title="Requested limit" [formControl]="formControlLoanAmount" [min]="1"
        [max]="maxLimit" [mark]="markTriggerSubject(formGroupCalculator)"></currency-input>
      </div>
    </div>
  </div>


  <div class="row mt-4">
    <div class="col-12" fxLayout="row" fxLayoutAlign="end center">
      <button mat-flat-button color="primary" class="w-lt-md-100" (click)="onCalculateEstimation($event)">Calculate
      repayment</button>
    </div>
  </div>
</div>
</div>
